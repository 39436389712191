import React, { useState, useEffect, useRef, createRef, useMemo } from "react";
import { Grid, TextField, Autocomplete, Button, IconButton, Tooltip } from "@mui/material";
import "react-h5-audio-player/lib/styles.css";
import instance from "../services/axiosConfig";
import { useSnackbar } from "notistack";
import TemplateQuestion from "../components/Data/TemplateQuestion";
import EditableLabel from "../components/Data/EditableLabel";
import { Template } from "../common/types";
import { hasArrayChanged, generateTemporaryID } from "../common/utils";
import { ReactComponent as CopyIcon } from "../assets/CopyIcon.svg";
import { ReactComponent as PasteIcon } from "../assets/PasteIcon.svg";
import AlertDialog from "./Modals/AlertDialog";

interface TemplateLocal {
    id: string;
    name: string;
    questions: string[];
    questionRefs: React.RefObject<HTMLTextAreaElement>[];
    edited: boolean;
}

interface TemplateDB {
    id: string;
    name: string;
    data: string[];
}

interface TemplatesProps {
    setChosenQuestions: (newQuestions: string[]) => void;
}

function Templates({ setChosenQuestions }: TemplatesProps) {
    const { enqueueSnackbar } = useSnackbar();

    const [templatesLocal, setTemplatesLocal] = useState<TemplateLocal[]>([]);
    const [templatesDB, setTemplatesDB] = useState<TemplateDB[]>([]);
    const [chosenTemplateId, setChosenTemplateId] = useState<string | null>(null);
    const [newQuestion, setNewQuestion] = useState("");
    const [isDeleteTemplateDialogOpen, setIsDeleteTemplateDialogOpen] = useState(false);

    const preserveInputFocus = useRef(false);

    const chosenTemplateLocal = templatesLocal.find((t) => t.id === chosenTemplateId) || null;
    const chosenTemplateDB = useMemo(
        () => templatesDB.find((t) => t.id === chosenTemplateId) || null,
        [chosenTemplateId, templatesDB]
    );
    const templateNameIsEmpty = chosenTemplateLocal !== null && chosenTemplateLocal.name === "";

    // GET TEMPLATE request
    useEffect(() => {
        instance
            .get<Template[]>(`/api/templates`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                setTemplatesLocal(
                    res.data.map(({ id, name, data }: TemplateDB) => ({
                        id,
                        name,
                        questions: [...data],
                        questionRefs: data.map(() => createRef<HTMLTextAreaElement>()),
                        edited: false,
                    }))
                );
                setTemplatesDB(
                    res.data.map(({ id, name, data }: TemplateDB) => ({
                        id,
                        name,
                        data: [...data],
                    }))
                );
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            });
    }, [enqueueSnackbar]);

    // Preserve input focus when new question added
    useEffect(() => {
        if (chosenTemplateLocal === null || preserveInputFocus.current === false) return;
        const refs = chosenTemplateLocal.questionRefs;
        const lastQuestionRef = refs[refs.length - 1];
        if (lastQuestionRef.current === null) {
            console.error("error: unable to keep focus on input field");
        } else {
            lastQuestionRef.current.focus();
            lastQuestionRef.current.selectionStart = 1;
        }
        preserveInputFocus.current = false;
    }, [chosenTemplateLocal]);

    // Pass chosen questions to parent component
    useEffect(() => {
        if (chosenTemplateLocal === null) setChosenQuestions([]);
        else setChosenQuestions(chosenTemplateLocal.questions);
    }, [chosenTemplateLocal, setChosenQuestions]);

    const handleSaveTemplate = () => {
        if (chosenTemplateId === null || chosenTemplateLocal === null || chosenTemplateLocal.edited === false) return;
        if (templateNameIsEmpty) {
            enqueueSnackbar("Пожалуйста дайте название шаблону!", {
                variant: "error",
            });
            return;
        }

        // POST TEMPLATE request
        if (chosenTemplateDB === null) {
            instance
                .post<{ id: string }>(
                    `/api/template`,
                    { name: chosenTemplateLocal.name, data: [...chosenTemplateLocal.questions] },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    setTemplatesDB((oldTemplates) =>
                        oldTemplates.concat({
                            id: res.data.id,
                            name: chosenTemplateLocal.name,
                            data: [...chosenTemplateLocal.questions],
                        })
                    );
                    setTemplatesLocal((oldTemplates) =>
                        oldTemplates.map((t) =>
                            t.id === chosenTemplateId ? { ...t, id: res.data.id, edited: false } : t
                        )
                    );
                    setChosenTemplateId(res.data.id);

                    enqueueSnackbar("Новый шаблон сохранён", {
                        variant: "info",
                    });
                })
                .catch((error) => {
                    enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                    });
                });
        }

        // PUT TEMPLATE request
        else {
            instance
                .put(
                    `/api/template/${chosenTemplateId}`,
                    { name: chosenTemplateLocal.name, data: [...chosenTemplateLocal.questions] },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    setTemplatesDB((oldTemplates) =>
                        oldTemplates.map((t) =>
                            t.id === chosenTemplateId
                                ? { ...t, name: chosenTemplateLocal.name, data: [...chosenTemplateLocal.questions] }
                                : t
                        )
                    );
                    setTemplatesLocal((oldTemplates) =>
                        oldTemplates.map((t) => (t.id === chosenTemplateId ? { ...t, edited: false } : t))
                    );

                    enqueueSnackbar("Изменения сохранены", {
                        variant: "info",
                    });
                })
                .catch((error) => {
                    enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                    });
                });
        }
    };

    const handleDeleteTemplate = () => {
        if (chosenTemplateId === null || chosenTemplateLocal === null) return;
        const localDeletionHandler = () => {
            setTemplatesLocal((oldTemplates) => oldTemplates.filter((t) => t.id !== chosenTemplateId));
            setChosenTemplateId(null);

            enqueueSnackbar("Шаблон успешно удалён!", {
                variant: "success",
            });
        };

        // DELETE TEMPLATE request
        if (chosenTemplateDB !== null) {
            instance
                .delete(`/api/template/${chosenTemplateId}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    setTemplatesDB((oldTemplates) => oldTemplates.filter((t) => t.id !== chosenTemplateId));
                    localDeletionHandler();
                })
                .catch((error) => {
                    enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                    });
                });
        }
        // Just delete local state if template is not saved in DB
        else localDeletionHandler();
    };

    const handleCreateTemplate = () => {
        if (templateNameIsEmpty) {
            enqueueSnackbar("Пожалуйста дайте название шаблону!", {
                variant: "error",
            });
            return;
        }

        const newId = generateTemporaryID();
        setTemplatesLocal((oldTemplates) =>
            oldTemplates.concat({ id: newId, name: "", questions: [], questionRefs: [], edited: true })
        );
        setChosenTemplateId(newId);
    };

    const handleEditTemplateName = (newValue: string) => {
        if (chosenTemplateId === null) return;
        const isEdited = chosenTemplateDB === null ? true : chosenTemplateDB.name !== newValue;
        setTemplatesLocal((oldTemplates) =>
            oldTemplates.map((t) => (t.id === chosenTemplateId ? { ...t, name: newValue, edited: isEdited } : t))
        );
    };

    const handleAddQuestion = (newValue: string) => {
        if (chosenTemplateId === null || chosenTemplateLocal === null) return;
        const newQuestions = chosenTemplateLocal.questions.concat(newValue);
        const newQuestionRefs = chosenTemplateLocal.questionRefs.concat(createRef<HTMLTextAreaElement>());
        const isEdited = chosenTemplateDB === null ? true : hasArrayChanged(newQuestions, chosenTemplateDB.data);

        setTemplatesLocal((oldTemplates) =>
            oldTemplates.map((t) =>
                t.id === chosenTemplateId
                    ? { ...t, questions: newQuestions, questionRefs: newQuestionRefs, edited: isEdited }
                    : t
            )
        );
        setNewQuestion("");

        preserveInputFocus.current = true;
    };

    const handleEditQuestion = (newValue: string, qIndex: number) => {
        if (chosenTemplateId === null || chosenTemplateLocal === null) return;
        const newQuestions = chosenTemplateLocal.questions.map((q, i) => (i === qIndex ? newValue : q));
        const isEdited = chosenTemplateDB === null ? true : hasArrayChanged(newQuestions, chosenTemplateDB.data);
        setTemplatesLocal((oldTemplates) =>
            oldTemplates.map((t) =>
                t.id === chosenTemplateId ? { ...t, questions: newQuestions, edited: isEdited } : t
            )
        );
    };

    const handleDeleteQuestion = (qIndex: number) => {
        if (chosenTemplateId === null || chosenTemplateLocal === null) return;
        const newQuestions = chosenTemplateLocal.questions.filter((_, i) => i !== qIndex);
        const newQuestionRefs = chosenTemplateLocal.questionRefs.filter((_, i) => i !== qIndex);
        const isEdited = chosenTemplateDB === null ? true : hasArrayChanged(newQuestions, chosenTemplateDB.data);

        setTemplatesLocal((oldTemplates) =>
            oldTemplates.map((t) =>
                t.id === chosenTemplateId
                    ? { ...t, questions: newQuestions, questionRefs: newQuestionRefs, edited: isEdited }
                    : t
            )
        );
    };

    const handleCopyQuestions = () => {
        if (chosenTemplateLocal === null || chosenTemplateLocal.questions.length === 0) return;
        navigator.clipboard
            .writeText(chosenTemplateLocal.questions.join("\n"))
            .then(() => {
                enqueueSnackbar("Вопросы скопированы", { variant: "info" });
            })
            .catch((err) => {
                enqueueSnackbar("Не удалось скопировать вопросы!", { variant: "error" });
            });
    };

    const handlePasteQuestions = () => {
        if (chosenTemplateLocal === null) return;
        navigator.clipboard
            .readText()
            .then((res) => {
                const newQuestions = res.split("\n").map((q) => q.trim());
                const newQuestionRefs = newQuestions.map(() => createRef<HTMLTextAreaElement>());
                const isEdited =
                    chosenTemplateDB === null ? true : hasArrayChanged(newQuestions, chosenTemplateDB.data);
                setTemplatesLocal((oldTemplates) =>
                    oldTemplates.map((t) =>
                        t.id === chosenTemplateId
                            ? { ...t, questions: newQuestions, questionRefs: newQuestionRefs, edited: isEdited }
                            : t
                    )
                );
                enqueueSnackbar("Вопросы успешно вставлены", { variant: "info" });
            })
            .catch((err) => {
                enqueueSnackbar("Не удалось вставить вопросы!", { variant: "error" });
            });
    };

    return (
        <Grid item sx={{ width: "50%", marginRight: "32px" }}>
            <Grid container wrap="nowrap" alignItems="center" style={{ marginBottom: "32px" }}>
                <Autocomplete
                    disablePortal
                    readOnly={templateNameIsEmpty}
                    id="template-autocomplete"
                    options={templatesLocal.map((t) => t.id)}
                    getOptionLabel={(tId) => {
                        const found = templatesLocal.find((t) => t.id === tId);
                        if (found === undefined) {
                            return "Загрузка шаблонов...";
                        } else {
                            return found.name;
                        }
                    }}
                    value={chosenTemplateId}
                    onChange={(event: any, newTemplateId: string | null) => {
                        setChosenTemplateId(newTemplateId);
                    }}
                    sx={{ flexGrow: 1 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Шаблоны"
                            onClick={() => {
                                if (templateNameIsEmpty) {
                                    enqueueSnackbar("Пожалуйста дайте название шаблону!", {
                                        variant: "error",
                                    });
                                }
                            }}
                        />
                    )}
                />
                <Button
                    variant="contained"
                    onClick={handleCreateTemplate}
                    color="primary"
                    style={{ margin: "0 0 0 16px" }}
                >
                    Создать
                </Button>
                {chosenTemplateId !== null && (
                    <Button
                        variant="contained"
                        onClick={() => setIsDeleteTemplateDialogOpen(true)}
                        color="error"
                        style={{ margin: "0 0 0 16px" }}
                    >
                        Удалить
                    </Button>
                )}
                <AlertDialog
                    open={isDeleteTemplateDialogOpen}
                    onClose={() => setIsDeleteTemplateDialogOpen(false)}
                    title="Удалить текущий шаблон?"
                    description="Вы уверены, что хотите удалить текущий шаблон?"
                >
                    <Button
                        onClick={() => setIsDeleteTemplateDialogOpen(false)}
                        autoFocus
                        variant="outlined"
                        color="tertiary"
                    >
                        Не удалять
                    </Button>
                    <Button
                        onClick={() => {
                            handleDeleteTemplate();
                            setIsDeleteTemplateDialogOpen(false);
                        }}
                        variant="contained"
                        color="error"
                    >
                        Удалить!
                    </Button>
                </AlertDialog>
            </Grid>
            <Grid
                container
                flexDirection="column"
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 2, sm: 4, md: 8, lg: 12 }}
                style={{ marginBottom: 32 }}
            >
                <Grid item xs={2} sm={4} md={4} lg={12}>
                    <Grid container alignItems="center">
                        <Grid item>
                            {chosenTemplateLocal !== null && chosenTemplateLocal.questions.length > 0 && (
                                <Tooltip title="Скопировать вопросы">
                                    <IconButton
                                        style={{ margin: 0 }}
                                        aria-label="copy"
                                        color="primary"
                                        onClick={handleCopyQuestions}
                                        size="medium"
                                    >
                                        <CopyIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid>
                        <Grid item>
                            {chosenTemplateLocal !== null && (
                                <Tooltip title="Вставить вопросы из буфера обмена">
                                    <IconButton
                                        style={{ margin: 0 }}
                                        aria-label="paste"
                                        color="primary"
                                        onClick={handlePasteQuestions}
                                        size="medium"
                                    >
                                        <PasteIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid>
                        <Grid item flexGrow={1} style={{ marginLeft: "8px" }}>
                            <EditableLabel
                                value={chosenTemplateLocal !== null ? chosenTemplateLocal.name : "Выберите шаблон"}
                                readOnly={chosenTemplateId === null}
                                onChange={(newValue) => handleEditTemplateName(newValue)}
                                placeholder="Название шаблона"
                            />
                        </Grid>
                        {chosenTemplateLocal !== null && chosenTemplateLocal.edited === true && (
                            <Grid item>
                                <Button
                                    variant="contained"
                                    style={{ margin: "0 0 0 16px" }}
                                    onClick={handleSaveTemplate}
                                >
                                    Сохранить
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {chosenTemplateLocal !== null &&
                    chosenTemplateLocal.questions.map((q: string, qIndex: number) => (
                        <Grid item key={qIndex} sx={{ flexGrow: 1 }} xs={2} sm={4} md={4} lg={12}>
                            <TemplateQuestion
                                ref={chosenTemplateLocal.questionRefs[qIndex]}
                                disableRipple={true}
                                value={q}
                                placeholder="Новый вопрос"
                                qIndex={qIndex}
                                type="standard"
                                onChange={(newValue) => handleEditQuestion(newValue, qIndex)}
                                onDelete={() => handleDeleteQuestion(qIndex)}
                                showDelete={true}
                            />
                        </Grid>
                    ))}
                {chosenTemplateLocal !== null && (
                    <Grid item xs={2} sm={4} md={4} lg={12}>
                        <TemplateQuestion
                            disableRipple={true}
                            value={newQuestion}
                            placeholder="Новый вопрос"
                            type="standard"
                            onChange={(newValue) => handleAddQuestion(newValue)}
                            onDelete={() => {}}
                            showDelete={false}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

export default React.memo(Templates);
