import {
    Backdrop,
    Box,
    Button,
    Fade,
    Grid,
    IconButton,
    Modal,
    TextField,
    ThemeProvider,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef } from "material-react-table";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { User, UserGroup } from "../common/types";
import instance from "../services/axiosConfig";
import { ReactComponent as CleanIcon } from "../assets/CleanIcon.svg";
import ConfirmationModal from "../components/Modals/ConfirmationModal";
import { getTimeString } from "../common/utils";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: 0,
};

export default function Group() {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [group, setGroup] = useState<UserGroup>();
    const [users, setUsers] = useState<User[]>([]);
    const [groupName, setGroupName] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [openGroupModal, setOpenGroupModal] = useState<boolean>(false);
    const [openUserModal, setOpenUserModal] = useState<boolean>(false);
    const [openDeleteGroupModal, setOpenDeleteGroupModal] = useState<boolean>(false);
    const [openDeleteUserModal, setOpenDeleteUserModal] = useState<boolean>(false);
    const [user, setUser] = useState<User>();

    useEffect(() => {
        instance
            .get<UserGroup>("api/group", {
                headers: {
                    Accept: "*/*",
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                setGroup(res.data);
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            });
    }, [enqueueSnackbar]);

    useEffect(() => {
        if (!group) {
            return;
        }
        instance
            .get<User[]>("api/group/users", {
                headers: {
                    Accept: "*/*",
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                if (res.data !== null) {
                    setUsers(res.data);
                }
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            });
    }, [enqueueSnackbar, group]);

    const columns = useMemo<MRT_ColumnDef<User>[]>(
        () => [
            {
                accessorKey: "id",
                header: "ID",
                enableEditing: false,
                size: 100,
            },
            {
                accessorKey: "username", //access nested data with dot notation
                header: "Name",
                enableHiding: false,
                size: 150,
            },
            {
                accessorFn: (row) => (row.active ? "Активен" : "Не активен"),
                id: "active",
                header: "Active",
                size: 150,
            },
            {
                accessorFn: (row) => Number(row.role),
                id: "role",
                header: "Role",
                size: 150,
            },
            {
                accessorFn: (row) => getTimeString(row.created_at),
                id: "created_at",
                header: "Created at",
                enableHiding: false,
                size: 200,
            },
        ],
        []
    );

    const handleOpenGroupModal = () => setOpenGroupModal(true);
    const handleCloseGroupModal = () => setOpenGroupModal(false);
    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setGroupName(event.target.value);
    const onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value);
    const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);
    const onConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setConfirmPassword(event.target.value);

    const handleOpenUserModal = () => setOpenUserModal(true);
    const handleCloseUserModal = () => setOpenUserModal(false);

    const handleOpenDeleteGroupModal = () => setOpenDeleteGroupModal(true);
    const handleCloseDeleteGroupModal = () => setOpenDeleteGroupModal(false);
    const handleOpenDeleteUserModal = (row: MRT_Row<User>) => {
        setOpenDeleteUserModal(true);
        setUser(row.original);
    };
    const handleCloseDeleteUserModal = () => setOpenDeleteUserModal(false);

    // const users: User[] = [
    //     {
    //         username: "aboba",
    //         active: false,
    //         role: UserRole.Creator,
    //         created_at: 11111,
    //         id: "a",
    //         patron: "aba",
    //         group: {} as UserGroup,
    //         group_id: "aaa",
    //     },
    // ];

    const handleCreateGroup = () => {
        handleCloseGroupModal();
        instance
            .post<UserGroup>(
                "api/group",
                { name: groupName },
                {
                    headers: {
                        Accept: "*/*",
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                setGroup(res.data);
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            });
    };

    const handleDeleteGroup = () => {
        handleCloseDeleteGroupModal();
        instance
            .delete(`/api/group/${group?.id}`, {
                headers: {
                    Accept: "*/*",
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            })
            .then(() => {
                setGroup({} as UserGroup);
                enqueueSnackbar("Группа успешно удалена", {
                    variant: "success",
                });
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            });
    };

    const handleCreateUser = () => {
        handleCloseUserModal();
        instance
            .post(
                "/api/sign-up",
                {
                    username: username,
                    password: password,
                    confirmation_password: confirmPassword,
                },
                {
                    headers: {
                        Accept: "*/*",
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                instance
                    .post<User[]>(
                        "api/group/user",
                        { username: username },
                        {
                            headers: {
                                Accept: "*/*",
                                "Access-Control-Allow-Origin": "*",
                                "Content-Type": "application/json",
                            },
                        }
                    )
                    .then((res) => {
                        setUsers(res.data);
                        handleCloseUserModal();
                    })
                    .catch((error) => {
                        enqueueSnackbar(error.response.data.error, {
                            variant: "error",
                        });
                    });
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            });
    };

    const handleDeleteUser = () => {
        handleCloseDeleteUserModal();
        instance
            .delete(`/api/group/user/${user?.id}`, {
                headers: {
                    Accept: "*/*",
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            })
            .then(() => {
                setUsers((uu) => uu.filter((u) => u.id !== user?.id));
                setUser({} as User);
                enqueueSnackbar("Пользователь успешно удален", {
                    variant: "success",
                });
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container justifyContent="space-between" style={{ marginBottom: 32 }}>
                <Typography variant="h3" style={{ color: "#5D6570" }}>
                    Группа
                </Typography>
                <Grid item>
                    {group === undefined ? (
                        <Button variant="contained" style={{ marginRight: 0 }} onClick={handleOpenGroupModal}>
                            Создать группу
                        </Button>
                    ) : (
                        <Grid container>
                            <Button variant="contained" onClick={handleOpenUserModal}>
                                Создать пользователя
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                style={{ marginRight: 0 }}
                                onClick={handleOpenDeleteGroupModal}
                            >
                                Удалить группу
                            </Button>
                        </Grid>
                    )}
                </Grid>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openGroupModal}
                    onClose={handleCloseGroupModal}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={openGroupModal}>
                        <Box sx={style}>
                            <Typography variant="h5" style={{ color: "#5D6570" }}>
                                Создание группы
                            </Typography>
                            <TextField
                                required
                                id="name"
                                size="small"
                                label="Название"
                                value={groupName}
                                onChange={onNameChange}
                                style={{ marginTop: 32, marginBottom: 32 }}
                            />
                            <Button variant="contained" onClick={handleCreateGroup} disabled={groupName === ""}>
                                Создать группу
                            </Button>
                        </Box>
                    </Fade>
                </Modal>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openUserModal}
                    onClose={handleCloseUserModal}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={openUserModal}>
                        <Box sx={style}>
                            <Typography variant="h5" style={{ color: "#5D6570" }}>
                                Создание пользователя
                            </Typography>
                            <TextField
                                required
                                id="username"
                                size="small"
                                label="Имя пользователя"
                                value={username}
                                onChange={onUsernameChange}
                                style={{ marginTop: 32, marginBottom: 16 }}
                            />
                            <TextField
                                required
                                id="password"
                                size="small"
                                label="Пароль"
                                value={password}
                                onChange={onPasswordChange}
                                style={{ marginBottom: 16 }}
                            />
                            <TextField
                                required
                                id="confirmPassword"
                                size="small"
                                label="Подтверждение пароля"
                                value={confirmPassword}
                                onChange={onConfirmPasswordChange}
                                style={{ marginBottom: 32 }}
                            />
                            <Button
                                variant="contained"
                                onClick={handleCreateUser}
                                disabled={
                                    username === "" ||
                                    password === "" ||
                                    confirmPassword === "" ||
                                    password !== confirmPassword
                                }
                            >
                                Создать пользователя
                            </Button>
                        </Box>
                    </Fade>
                </Modal>
                <ConfirmationModal
                    open={openDeleteGroupModal}
                    onClose={handleCloseDeleteGroupModal}
                    title="Удаление группы"
                    description="Вы уверены, что хотите удалить группу и всех пользователей в ней?"
                    buttonText="Удалить группу"
                    buttonHandle={handleDeleteGroup}
                />
            </Grid>
            <MaterialReactTable
                columns={columns}
                data={users}
                enableColumnActions={false}
                enableColumnFilters={false}
                enablePagination={false}
                enableSorting={false}
                enableRowActions
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                        <Tooltip title="Delete">
                            <IconButton
                                style={{ margin: "0" }}
                                aria-label="delete"
                                color="error"
                                onClick={() => handleOpenDeleteUserModal(row)}
                                size="small"
                            >
                                <CleanIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            />
            <ConfirmationModal
                open={openDeleteUserModal}
                onClose={handleCloseDeleteUserModal}
                title="Удаление пользователя"
                description={`Вы уверены, что хотите удалить пользователя ${user?.username}?`}
                buttonText="Удалить пользователя"
                buttonHandle={handleDeleteUser}
            />
        </ThemeProvider>
    );
}
