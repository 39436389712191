import { Grid, Button, IconButton } from "@mui/material";
import CustomAudioPlayer from "../Common/CustomAudioPlayer";
import React from "react";
import ResponseData, { StatusResponseData } from "../Data/ResponseData";
import EditableLabel from "../Data/EditableLabel";
import { useSnackbar } from "notistack";
import { ReactComponent as CleanIcon } from "../../assets/CleanIcon.svg";
import RecordingLoader from "./RecordingLoader";

interface MicroSourceItemProps {
    chosenQuestions: string[];
    name: string;
    savingRecording: boolean;
    blobURL: string;
    answers: string;
    statusAnswers: StatusResponseData;
    savingName: boolean;
    handleChangeName: (value: string) => void;
    handleDeleteRecording: () => void;
    handleDeleteAnswers: () => void;
    handleFetchAnswers: () => void;
    handleSaveAudioName: () => void;
}

export default function MicroSourceItem(props: MicroSourceItemProps) {
    const { enqueueSnackbar } = useSnackbar();
    const buttonDisabled =
        props.statusAnswers === StatusResponseData.waitingForResponse ||
        props.chosenQuestions.length === 0 ||
        props.savingRecording;

    const warnOnSaving = () => {
        enqueueSnackbar("Подождите, аудио сохраняется!", { variant: "error" });
    };

    return (
        <>
            <Grid item>
                <Grid container wrap="nowrap" alignItems="center">
                    <Grid item flexGrow={1}>
                        <EditableLabel
                            value={props.name}
                            readOnly={false}
                            onChange={(value) => props.handleChangeName(value)}
                            placeholder={`Дайте название звонку`}
                            onBlur={props.handleSaveAudioName}
                            loading={props.savingName}
                        />
                    </Grid>
                    <Grid item>
                        <IconButton
                            style={{ margin: 0, padding: "8px" }}
                            aria-label="clean"
                            color="error"
                            onClick={props.savingRecording ? warnOnSaving : props.handleDeleteRecording}
                            size="small"
                        >
                            <CleanIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container wrap="nowrap" alignItems="center" gap={3}>
                    <CustomAudioPlayer blobURL={props.blobURL} />
                    <Button
                        variant="contained"
                        style={{
                            margin: "0 0 0 16px",
                        }}
                        onClick={props.handleFetchAnswers}
                        disabled={buttonDisabled}
                    >
                        Получить ответы
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                {props.savingRecording ? (
                    <RecordingLoader text="Сохраняем запись..." />
                ) : (
                    <ResponseData
                        status={props.statusAnswers}
                        data={props.answers}
                        handleClean={props.handleDeleteAnswers}
                        actionName={"Ответы"}
                        actionNameEn={"Answers"}
                        resultName={"Ответы на вопросы"}
                    />
                )}
            </Grid>
        </>
    );
}
