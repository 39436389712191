import Box from "@mui/material/Box";
import { ReactNode } from "react";

interface CommonWrapperProps {
    children: ReactNode;
}

export function MainComponentWrapper(props: CommonWrapperProps) {
    return (
        <Box
            sx={{ "& button": { m: 1, fontWeight: "bold" } }}
            style={{
                flexGrow: 1,
                marginTop: 104,
                marginRight: 32,
                marginLeft: 110,
            }}
        >
            {props.children}
        </Box>
    );
}

export function FlexWrapper(props: CommonWrapperProps) {
    return <Box style={{ display: "flex", flexDirection: "row" }}>{props.children}</Box>;
}
