import { Button, TextField, Grid, IconButton, InputAdornment } from "@mui/material";
import { ReactComponent as CleanIcon } from "../../assets/CleanIcon.svg";
import React, { forwardRef } from "react";

interface InputProps {
    disableRipple: boolean;
    value: string;
    placeholder: string;
    qIndex?: number;
    type: string;
    onChange: (newValue: string) => void;
    onDelete: () => void;
    showDelete: boolean;
}

export default forwardRef(function TemplateQuestion(
    props: InputProps,
    ref: React.ForwardedRef<HTMLTextAreaElement> | undefined
) {
    return (
        <Button
            component="label"
            variant="outlined"
            disableRipple={props.disableRipple}
            style={{ display: "block", margin: "0 auto", padding: "8px 16px" }}
        >
            <Grid container style={{ height: props.showDelete ? "auto" : "30px", alignItems: "center" }}>
                <Grid item style={{ flexGrow: 1 }}>
                    <TextField
                        multiline
                        fullWidth
                        placeholder={props.placeholder}
                        id={props.qIndex !== undefined ? `question-${props.qIndex}` : "question-new"}
                        type={props.type}
                        variant="standard"
                        onChange={(e) => props.onChange(e.target.value)}
                        value={props.value}
                        style={{ padding: 0, stroke: "none", fontSize: "16px", lineHeight: "16px", fontWeight: "bold" }}
                        inputRef={ref}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment:
                                props.qIndex !== undefined ? (
                                    <InputAdornment position="start">{props.qIndex + 1}.</InputAdornment>
                                ) : null,
                        }}
                        inputProps={{
                            style: {
                                padding: 0,
                                color: "#5D6570",
                            },
                        }}
                    />
                </Grid>
                {props.showDelete && (
                    <Grid item>
                        <IconButton
                            style={{ margin: 0 }}
                            aria-label="clean"
                            color="primary"
                            onClick={props.onDelete}
                            size="small"
                        >
                            <CleanIcon />
                        </IconButton>
                    </Grid>
                )}
            </Grid>
        </Button>
    );
});
