import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { StatusResponseData } from "../Data/ResponseData";
import { DataMicro, initialDataMicro } from "../Recording";
import { useSnackbar } from "notistack";
import { ReactComponent as RecordIcon } from "../../assets/RecordIcon.svg";
import { ReactComponent as RecordActiveIcon } from "../../assets/RecordActiveIcon.svg";
import InputData from "../Data/InputData";
import { VisuallyHiddenInput } from "../../common/styled";
import Recorder from "../../services/recorder";
import { getTimeNow } from "../../common/utils";
import instance from "../../services/axiosConfig";
import MicroSourceItem from "./MicroSourceItem";

interface MicroSourceProps {
    chosenQuestions: string[];
    dataMicro: DataMicro[];
    setDataMicro: React.Dispatch<React.SetStateAction<DataMicro[]>>;
    handleFetchAudioList: () => void;
    handleSaveAudioName: (id: number, name: string, beforeCallback: () => void, afterCallback: () => void) => void;
}

export default function MicroSource(props: MicroSourceProps) {
    const { enqueueSnackbar } = useSnackbar();
    const [isRecording, setIsRecording] = useState(false);

    const setPropsForRecording = (id: number, newProps: Partial<DataMicro>) => {
        props.setDataMicro((data) =>
            data.map((recording) => (recording.id === id ? { ...recording, ...newProps } : recording))
        );
    };

    const handleFetchAnswers = (id: number) => {
        setPropsForRecording(id, { statusAnswers: StatusResponseData.waitingForResponse });
        instance
            .post(
                "/api/transcribe/call",
                { id, questions: props.chosenQuestions.join("\n") },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                setPropsForRecording(id, { answers: res.data.answers, statusAnswers: StatusResponseData.dataReceived });

                enqueueSnackbar("Ответы найдены успешно!", { variant: "success" });
            })
            .catch((err) => {
                setPropsForRecording(id, { statusAnswers: StatusResponseData.responseError });

                enqueueSnackbar("Произошла ошибка во время поиска ответов!", { variant: "error" });
            });
    };

    const handleRecord = async () => {
        if (isRecording) {
            setIsRecording(false);
            enqueueSnackbar("Запись приостановлена.", { variant: "info" });
            try {
                const blobData = await Recorder.pause();
                const name = "Запись (микро) от " + getTimeNow();
                const blobURL = URL.createObjectURL(blobData);

                props.setDataMicro((data) =>
                    data.concat({ ...initialDataMicro, name, blobData, blobURL, savingRecording: true })
                );

                const formData = new FormData();
                formData.append("name", name);
                formData.append("data", blobData);
                formData.append("input_type", "Micro");
                const res = await instance.post<{ id: number }>("api/audio", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                setPropsForRecording(-1, { id: res.data.id });

                props.handleFetchAudioList();

                enqueueSnackbar("Запись успешно сохранена!", { variant: "success" });
            } catch (error: any) {
                enqueueSnackbar(
                    "Возникла ошибка при сохранении аудио! " +
                        (error?.response?.data?.error ? error.response.data.error : error)
                );
            } finally {
                props.setDataMicro((data) =>
                    data.map((recording, i) =>
                        i === data.length - 1 ? { ...recording, savingRecording: false } : recording
                    )
                );
            }
        } else {
            Recorder.start()
                .then(() => {
                    enqueueSnackbar("Запись начата", { variant: "info" });
                    setIsRecording(true);
                })
                .catch((error) => {
                    enqueueSnackbar("Ошибка при попытке начать запись: " + error, {
                        variant: "error",
                    });
                });
        }
    };

    const handleChangeName = (value: string, id: number) => {
        setPropsForRecording(id, { name: value });
    };

    const handleDeleteRecording = (id: number) => {
        props.setDataMicro((data) => data.filter((recording) => recording.id !== id));

        enqueueSnackbar(
            "Аудио было удалено из списка текущих. Вы всегда можете найти удаленное аудио в списке сохраненых.",
            { variant: "success" }
        );
    };

    const handleDeleteAnswers = (id: number) => {
        setPropsForRecording(id, { answers: "", statusAnswers: StatusResponseData.idle });

        enqueueSnackbar("Ответы успешно удалены!", { variant: "success" });
    };

    return (
        <>
            <Grid item>
                <InputData
                    disableRipple={true}
                    label={isRecording ? "Идёт запись..." : "Записать с микрофона"}
                    inputComponent={
                        <Typography
                            fontSize="12px"
                            lineHeight="16px"
                            fontWeight="bold"
                            style={{
                                color: "#949698",
                                textTransform: "none",
                            }}
                        >
                            {isRecording ? "Чтобы остановить запись, нажмите ещё раз" : "Нажмите, чтобы начать запись"}
                            <VisuallyHiddenInput type="button" onClick={handleRecord} />
                        </Typography>
                    }
                    inputIcon={isRecording ? <RecordActiveIcon /> : <RecordIcon />}
                    resized={false}
                />
            </Grid>
            {props.dataMicro.map((recording) => (
                <MicroSourceItem
                    {...recording}
                    chosenQuestions={props.chosenQuestions}
                    handleChangeName={(value) => handleChangeName(value, recording.id)}
                    handleDeleteRecording={() => handleDeleteRecording(recording.id)}
                    handleDeleteAnswers={() => handleDeleteAnswers(recording.id)}
                    handleFetchAnswers={() => handleFetchAnswers(recording.id)}
                    handleSaveAudioName={() =>
                        props.handleSaveAudioName(
                            recording.id,
                            recording.name,
                            () => setPropsForRecording(recording.id, { savingName: true }),
                            () => setPropsForRecording(recording.id, { savingName: false })
                        )
                    }
                />
            ))}
        </>
    );
}
