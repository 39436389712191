import React, { useState, useRef, useEffect } from "react";
import { Box, IconButton, Slider, Tooltip, Typography } from "@mui/material";
import { PlayArrow, Pause, VolumeUp, GetApp as GetAppIcon } from "@mui/icons-material";
import styled from "@emotion/styled";

interface CustomAudioPlayerProps {
    blobURL: string;
}

const AudioPlayerContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    width: "100%",
    background: "#ffffff",
    padding: "8px",
    borderRadius: "8px",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
});

const DownloadButton = styled("a")({
    textDecoration: "none",
    marginLeft: "16px",
});

export default function CustomAudioPlayer(props: CustomAudioPlayerProps) {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(100);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume / 100;
        }
    }, [volume]);

    const togglePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleTimeUpdate = () => {
        if (audioRef.current) {
            setCurrentTime(audioRef.current.currentTime);
        }
    };

    const handleDurationChange = () => {
        if (audioRef.current) {
            setDuration(audioRef.current.duration);
        }
    };

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        if (audioRef.current) {
            audioRef.current.currentTime = newValue as number;
            setCurrentTime(newValue as number);
        }
    };

    const handleAudioEnd = () => {
        setIsPlaying(false);
        setCurrentTime(0);
    };

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    return (
        <AudioPlayerContainer>
            <IconButton onClick={togglePlayPause} color="primary">
                {isPlaying ? <Pause /> : <PlayArrow />}
            </IconButton>
            <Typography variant="body2" sx={{ paddingRight: "16px" }}>
                <Box>{formatTime(currentTime)}</Box>
                <Box>{formatTime(duration)}</Box>
            </Typography>
            <Slider
                value={currentTime}
                min={0}
                max={duration}
                onChange={handleSliderChange}
                style={{ marginLeft: "16px", marginRight: "16px" }}
            />
            <VolumeUp />
            <Slider
                value={volume}
                min={0}
                max={100}
                onChange={(event, newValue) => setVolume(newValue as number)}
                style={{ width: "100px", marginLeft: "8px", marginRight: "8px" }}
            />
            <DownloadButton href={props.blobURL} download>
                <Tooltip title="Скачать" arrow>
                    <IconButton color="primary">
                        <GetAppIcon />
                    </IconButton>
                </Tooltip>
            </DownloadButton>
            <audio
                ref={audioRef}
                src={props.blobURL}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleDurationChange}
                onEnded={handleAudioEnd}
            />
        </AudioPlayerContainer>
    );
}
