import { useMemo } from "react";
import { HistoryGroup } from "../../common/types";
import { MRT_ColumnDef, MaterialReactTable } from "material-react-table";
import { getTimeString } from "../../common/utils";

interface InputProps {
    data: HistoryGroup[];
}

export default function TableGroup(props: InputProps) {
    const columns = useMemo<MRT_ColumnDef<HistoryGroup>[]>(
        () => [
            {
                accessorKey: "username",
                header: "Username",
                size: 100,
            },
            {
                accessorKey: "type",
                header: "Type",
                size: 100,
            },
            {
                accessorFn: (row) => getTimeString(row.created_at),
                id: "created_at",
                header: "Created at",
                enableHiding: false,
                size: 100,
            },
            {
                accessorFn: (row) => JSON.stringify(row.input),
                id: "input",
                header: "Input",
                size: 300,
            },
            {
                accessorFn: (row) => JSON.stringify(row.output),
                id: "output",
                header: "Output",
                size: 400,
            },
        ],
        []
    );

    return (
        <MaterialReactTable
            columns={columns}
            data={props.data}
            // initialState={{ density: "compact" }}
            // enableColumnResizing={true}
            // defaultColumn={{
            //     maxSize: 500,
            //     minSize: 80,
            // }}
            // enableColumnActions={false}
            // enableColumnFilters={false}
            // enablePagination={false}
            // enableSorting={false}
        />
    );
}
