import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    ThemeProvider,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import instance from "../services/axiosConfig";
import { useSnackbar } from "notistack";
import TableTranscribation from "../components/History/TableTranscribation";
import TableQuestions from "../components/History/TableQuestions";
import TableSearch from "../components/History/TableSearch";
import { User, UserRole } from "../common/types";
import TableGroup from "../components/History/TableGroup";
import TableCall from "../components/History/TableCall";

enum HistoryEnum {
    None,
    Transcribation,
    Search,
    Questions,
    Call,
    Group,
}

export default function History() {
    const theme = useTheme();
    const [history, setHistory] = useState<HistoryEnum>(HistoryEnum.None);
    const [dataHistory, setDataHistory] = useState<[]>([]);
    const [user, setUser] = useState<User>();

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        instance
            .get(`api/user`, {
                headers: {
                    Accept: "*/*",
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                setUser(res.data);
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            });
    }, [enqueueSnackbar]);

    const handleChange = (event: SelectChangeEvent) => {
        let val = +event.target.value;
        if (val === HistoryEnum.Group) {
            instance
                .get(`api/group/history`, {
                    headers: {
                        Accept: "*/*",
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    if (res.data == null) {
                        enqueueSnackbar("Нет истории!", {
                            variant: "info",
                        });
                        return;
                    }
                    enqueueSnackbar("История получена успешно!", { variant: "success" });
                    setHistory(val);
                    setDataHistory(res.data);
                })
                .catch((error) => {
                    enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                    });
                });
            return;
        }
        if (val !== HistoryEnum.None) {
            handleHistoryPost(+event.target.value);
        }
    };

    const handleHistoryPost = (id: number) => {
        instance
            .get(`api/history/${id}`, {
                headers: {
                    Accept: "*/*",
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                if (res.data == null) {
                    enqueueSnackbar("Истории нет!", {
                        variant: "info",
                    });
                    return;
                }
                enqueueSnackbar("История получена успешно!", { variant: "success" });
                setHistory(id);
                setDataHistory(res.data);
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
                // setDataHistory([]);
            });
    };

    const dataHistoryJSX = {
        [HistoryEnum.None]: <></>,
        [HistoryEnum.Transcribation]: <TableTranscribation data={dataHistory} />,
        [HistoryEnum.Search]: <TableSearch data={dataHistory} />,
        [HistoryEnum.Questions]: <TableQuestions data={dataHistory} />,
        [HistoryEnum.Call]: <TableCall data={dataHistory} />,
        [HistoryEnum.Group]: <TableGroup data={dataHistory} />,
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container justifyContent="space-between" style={{ marginBottom: 32 }}>
                <Typography variant="h3" style={{ color: "#5D6570" }}>
                    История
                </Typography>
                <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                    <InputLabel id="demo-select-small-label">История</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={history.toString()}
                        label="История"
                        onChange={handleChange}
                    >
                        <MenuItem value="">
                            <em>-</em>
                        </MenuItem>
                        <MenuItem value={HistoryEnum.Transcribation}>Транскрибация</MenuItem>
                        <MenuItem value={HistoryEnum.Search}>Поиск компаний</MenuItem>
                        <MenuItem value={HistoryEnum.Questions}>Вопросы кандидату</MenuItem>
                        <MenuItem value={HistoryEnum.Call}>Телефонное интервью</MenuItem>
                        {user?.role === UserRole.Master ? <MenuItem value={HistoryEnum.Group}>Группа</MenuItem> : <></>}
                    </Select>
                </FormControl>
            </Grid>
            {dataHistoryJSX[history]}
        </ThemeProvider>
    );
}
