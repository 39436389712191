import { Button, Grid } from "@mui/material";
import ResponseData, { StatusResponseData } from "../Data/ResponseData";
import { DataYouTube } from "../Recording";
import ReactPlayer from "react-player";
import InputData from "../Data/InputData";
import InputDataTextField from "../Data/InputDataTextField";
import { ReactComponent as YouTubeIcon } from "../../assets/YouTubeIcon.svg";
import { useSnackbar } from "notistack";
import instance from "../../services/axiosConfig";
import { getTimeNow } from "../../common/utils";

interface YouTubeSourceProps {
    id: number;
    name: string;
    answers: string;
    statusAnswers: StatusResponseData;
    youTubeURL: string;
    showPlayer: boolean;
    chosenQuestions: string[];
    setDataYouTube: React.Dispatch<React.SetStateAction<DataYouTube>>;
    handleFetchAudioList: () => void;
}

export default function YouTubeSource(props: YouTubeSourceProps) {
    const { enqueueSnackbar } = useSnackbar();

    const buttonDisabled =
        props.statusAnswers === StatusResponseData.waitingForResponse ||
        props.chosenQuestions.length === 0 ||
        props.youTubeURL === "";

    const handleChangeYouTubeURL = (value: string) => {
        props.setDataYouTube((data) => ({ ...data, youTubeURL: value, id: -1, showPlayer: true }));
    };

    const handleCleanAnswers = () => {
        props.setDataYouTube((data) => ({
            ...data,
            answers: "",
            statusAnswers: StatusResponseData.idle,
            showPlayer: true,
        }));

        enqueueSnackbar("Ответы успешно очищены", { variant: "info" });
    };

    const handleFetchAnswers = async () => {
        props.setDataYouTube((data) => ({
            ...data,
            statusAnswers: StatusResponseData.waitingForResponse,
            showPlayer: false,
        }));

        try {
            let id = props.id;
            if (props.id === -1) {
                const formData = new FormData();
                formData.append("name", "Запись (ютуб) от " + getTimeNow());
                formData.append("data", props.youTubeURL);
                formData.append("input_type", "YouTube");

                const res = await instance.post<{ id: number }>("/api/audio", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                id = res.data.id;

                props.handleFetchAudioList();
            }
            const res = await instance.post(
                "/api/transcribe/youtube",
                {
                    id,
                    questions: props.chosenQuestions.join("\n"),
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            props.setDataYouTube((data) => ({
                ...data,
                id,
                answers: res.data.answers,
                statusAnswers: StatusResponseData.dataReceived,
            }));

            enqueueSnackbar("Ответы успешно найдены!", { variant: "success" });
        } catch (error: any) {
            props.setDataYouTube((data) => ({ ...data, statusAnswers: StatusResponseData.responseError }));

            enqueueSnackbar(
                "Возникла ошибка при нахождении ответов! " +
                    (error?.response?.data?.error ? error.response.data.error : error)
            );
        }
    };

    return (
        <>
            <Grid item>
                <Grid container wrap="nowrap" alignItems="center">
                    <Grid item flexGrow={1}>
                        <InputData
                            disableRipple={true}
                            label="Ссылка YouTube"
                            inputComponent={
                                <InputDataTextField
                                    value={props.youTubeURL}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleChangeYouTubeURL(event.target.value)
                                    }
                                    placeholder={"Введите ссылку YouTube"}
                                    id={"youTubeLink"}
                                    type={""}
                                    resized={false}
                                />
                            }
                            inputIcon={<YouTubeIcon />}
                            resized={false}
                        />
                    </Grid>
                    <Button
                        variant="contained"
                        style={{
                            margin: "0 0 0 16px",
                        }}
                        onClick={handleFetchAnswers}
                        disabled={buttonDisabled}
                    >
                        Получить ответы
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                {props.showPlayer && (
                    <ReactPlayer
                        style={{
                            marginTop: 16,
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                        url={props.youTubeURL}
                        controls={true}
                    />
                )}
                <ResponseData
                    status={props.statusAnswers}
                    data={props.answers}
                    handleClean={handleCleanAnswers}
                    actionName={"Ответы"}
                    actionNameEn={"Answers"}
                    resultName={"Ответы на вопросы"}
                />
            </Grid>
        </>
    );
}
