import React, { useMemo } from "react";
import { MRT_ColumnDef, MaterialReactTable } from "material-react-table";
import { HistorySearch } from "../../common/types";
import { getTimeString } from "../../common/utils";

interface InputProps {
    data: [];
}

export default function TableSearch(props: InputProps) {
    const columns = useMemo<MRT_ColumnDef<HistorySearch>[]>(
        () => [
            {
                accessorFn: (row) => row.owner.username,
                id: "owner.username",
                header: "Username",
                enableHiding: false,
                size: 100,
            },
            {
                accessorFn: (row) => getTimeString(row.created_at),
                id: "created_at",
                header: "Created at",
                enableHiding: false,
                size: 100,
            },
            {
                accessorKey: "amount",
                header: "Amount",
                size: 100,
            },
            {
                accessorKey: "region",
                header: "Region",
                size: 150,
            },
            {
                accessorKey: "type",
                header: "Type",
                size: 150,
            },
            {
                accessorKey: "data",
                header: "Search",
                size: 600,
            },
        ],
        []
    );
    return (
        <MaterialReactTable
            columns={columns}
            data={props.data}
            // enableColumnActions={false}
            // enableColumnFilters={false}
            // enablePagination={false}
            // enableSorting={false}
        />
    );
}
