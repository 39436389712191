import { CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";

interface RecordingLoaderProps {
    text: string;
}

export default function RecordingLoader(props: RecordingLoaderProps) {
    return (
        <Grid container direction="column" alignItems="center">
            <CircularProgress color="primary" />
            {props.text.split("\n").map((line) => (
                <Typography variant="h5" noWrap style={{ marginTop: "16px", marginBottom: "8px", color: "#5D6570" }}>
                    {line}
                </Typography>
            ))}
        </Grid>
    );
}
