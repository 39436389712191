import React from "react";
import { CircularProgress, Divider, Grid, IconButton, Typography } from "@mui/material";
import { ReactComponent as CopyIcon } from "../../assets/CopyIcon.svg";
import { ReactComponent as CleanIcon } from "../../assets/CleanIcon.svg";
import { useSnackbar } from "notistack";
import { formatAnswers } from "../../common/utils";

export enum StatusResponseData {
    idle,
    taskSelected,
    waitingForResponse,
    responseError,
    dataReceived,
}

interface InputProps {
    status: StatusResponseData;
    data: string;
    handleClean: () => void;
    actionName: string;
    actionNameEn: string;
    resultName?: string;
}

export default function ResponseData(props: InputProps) {
    const { enqueueSnackbar } = useSnackbar();

    const handleCopy = () => {
        navigator.clipboard
            .writeText(props.data)
            .then(() => {
                enqueueSnackbar(props.actionName + ": данные скопированы", { variant: "info" });
            })
            .catch((err) => {
                enqueueSnackbar(props.actionName + ": данные не удалось скопировать", { variant: "error" });
            });
    };

    const dataJSX = {
        [StatusResponseData.idle]: <></>,
        [StatusResponseData.taskSelected]: <></>,
        [StatusResponseData.waitingForResponse]: (
            <Grid>
                <Divider style={{ marginBottom: 16, marginTop: 16 }} />
                <Grid container justifyContent="center">
                    <Grid container direction="column" style={{ marginTop: "16px" }} alignItems="center">
                        <CircularProgress color="primary" />
                        <Typography
                            variant="h5"
                            noWrap
                            style={{ marginTop: "16px", marginBottom: "8px", color: "#5D6570" }}
                        >
                            {props.actionName} ...
                        </Typography>
                        <Typography variant="body1" style={{ color: "#949698" }} noWrap>
                            Пожалуйста, не обновляйте страницу
                        </Typography>
                        <Typography variant="body1" style={{ color: "#949698" }} noWrap>
                            Ожидайте ответ от сервера
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        ),
        [StatusResponseData.responseError]: (
            <Grid>
                <Divider style={{ marginBottom: 16, marginTop: 16 }} />
                <Grid container justifyContent="center">
                    <Divider style={{ marginBottom: 16, marginTop: 16 }} />
                    <Grid container direction="column" style={{ marginTop: "16px" }} alignItems="center">
                        <Typography
                            variant="h5"
                            noWrap
                            style={{ marginTop: "16px", marginBottom: "8px", color: "#EB0014" }}
                        >
                            Ошибка...
                        </Typography>
                        <Typography variant="body1" style={{ color: "#949698" }} noWrap>
                            Пожалуйста, попробуйте ещё раз
                        </Typography>
                        <Typography variant="body1" style={{ color: "#949698" }} noWrap>
                            Или попробуйте обновить страницу
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        ),
        [StatusResponseData.dataReceived]: (
            <Grid>
                <Divider style={{ marginBottom: 16, marginTop: 16 }} />
                <Grid container justifyContent="space-between">
                    <Typography variant="h5" noWrap style={{ marginTop: "8px", marginBottom: "8px", color: "#5D6570" }}>
                        {props.resultName || "Результат:"}
                    </Typography>
                    <Grid item>
                        <IconButton
                            style={{ marginTop: "8px" }}
                            aria-label="copy"
                            color="primary"
                            onClick={handleCopy}
                            size="small"
                        >
                            <CopyIcon />
                        </IconButton>
                        <IconButton
                            style={{ marginTop: "8px" }}
                            aria-label="clean"
                            color="primary"
                            onClick={props.handleClean}
                            size="small"
                        >
                            <CleanIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <Typography
                    whiteSpace={"pre-wrap"}
                    variant="body1"
                    style={{ color: "#949698" }}
                    dangerouslySetInnerHTML={{ __html: formatAnswers(props.data) }}
                ></Typography>
            </Grid>
        ),
    };
    return dataJSX[props.status];
}
