import { Button, Grid, Link, TextField, ThemeProvider, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import instance from "../services/axiosConfig";
import { useSnackbar } from "notistack";

export default function ContactSupport() {
    const theme = useTheme();

    const [name, setName] = useState<string>("");
    const [contact, setContact] = useState<string>("");
    const [text, setText] = useState<string>("");

    const { enqueueSnackbar } = useSnackbar();

    const handleSend = () => {
        instance
            .post(
                "/api/support-request",
                { name: name, contact: contact, text: text },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                enqueueSnackbar("Ваш запрос отправлен. В ближайшее время мы свяжемся с Вами!", {
                    variant: "success",
                });
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container justifyContent="space-between" style={{ marginBottom: 16 }}>
                <Typography variant="h3" style={{ color: "#5D6570" }}>
                    Связаться с поддержкой
                </Typography>
                <Button
                    variant="contained"
                    style={{ marginRight: 0 }}
                    onClick={handleSend}
                    disabled={name === "" || contact === "" || text === ""}
                >
                    Отправить
                </Button>
            </Grid>
            <Grid container style={{ marginBottom: 32 }}>
                <Typography variant="body1" style={{ color: "#949698", textAlign: "center" }}>
                    Если у Вас есть вопросы или Вам нужна помощь, пожалуйста, свяжитесь с нами. Мы ответим удобным Вам
                    способом в ближайшее время.
                </Typography>
                <Typography variant="body1" style={{ color: "#949698", textAlign: "center" }}>
                    Для получения дополнительной информации о работе сервиса, Вы можете посетить страницу{" "}
                    <Link href="user-guide">Руководство</Link>
                </Typography>
            </Grid>

            <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 2, sm: 4, md: 8, lg: 12 }}
                style={{ marginBottom: 32 }}
            >
                <Grid item xs={2} sm={4} md={4} lg={6}>
                    <TextField
                        required
                        fullWidth
                        id="name"
                        name="name"
                        label="Имя"
                        value={name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setName(event.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={2} sm={4} md={4} lg={6}>
                    <TextField
                        required
                        fullWidth
                        name="contact"
                        id="contact"
                        label="Адрес электронной почты / Telegram-никнейм "
                        value={contact}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setContact(event.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        multiline
                        name="text"
                        id="text"
                        label="Сообщение"
                        minRows={5}
                        maxRows={10}
                        value={text}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setText(event.target.value);
                        }}
                    />
                </Grid>
            </Grid>
            {/* <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="/" variant="body2">
                                Уже есть аккаунт? Войти
                            </Link>
                        </Grid>
                    </Grid> */}
        </ThemeProvider>
    );
}
