import { Navigate, Outlet, useLocation } from "react-router-dom";
import { FlexWrapper, MainComponentWrapper } from "../components/CommonWrappers";
import AppBarMain from "../components/AppBarMain";
import NavigationMenu from "../components/NavigationMenu/NavigationMenu";
import { useEffect, useState } from "react";
import authService from "../services/authService";
import Loading from "../components/Data/Loading";

interface InputProps {
    isNotAuth: boolean;
}

export default function AuthRoute(props: InputProps) {
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            authService.getAuth(token).then((res) => {});
        }
        setLoading(false);
    }, []);

    return loading ? (
        <Loading />
    ) : props.isNotAuth ? (
        location.pathname === "/sign-up" ? (
            <></>
        ) : (
            <Navigate to="/sign-in" state={{ from: location }} replace />
        )
    ) : (
        <FlexWrapper>
            <AppBarMain />
            <NavigationMenu />
            <MainComponentWrapper>
                <Outlet />
            </MainComponentWrapper>
        </FlexWrapper>
    );
}
