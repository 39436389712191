import moment from "moment";
import { EnqueueSnackbar } from "notistack";

export function getTimeNow() {
    const date = moment();
    return date.format("DD.MM.yyyy HH:mm:ss");
}

export function hasArrayChanged(initialArr: string[], testArr: string[]) {
    if (initialArr.length !== testArr.length) return true;
    for (let i = 0; i < initialArr.length; i++) {
        if (initialArr[i] !== testArr[i]) return true;
    }
    return false;
}

export function generateTemporaryID() {
    return crypto.randomUUID();
}

export function getTimeString(time: number) {
    const date = moment.unix(time);
    return date.format("DD.MM.yyyy HH:mm:ss");
}

export function handleError(error: any, enqueueSnackbar: EnqueueSnackbar, message: string = "Возникла ошибка!") {
    if (error?.response?.data?.error) {
        enqueueSnackbar(`${message}\n${error.response.data.error}`, { variant: "error" });
    } else {
        enqueueSnackbar(message, { variant: "error" });
        console.error(error);
    }
}

export function formatAnswers(text: string) {
    return text.replace(/\*+(.*?)\*+/g, "<strong><em>$1</em></strong>");
}
