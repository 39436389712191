import { AppBar, Avatar, Box, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../assets/Logo.svg";
import { Link } from "react-router-dom";
import authService from "../services/authService";
import { useSnackbar } from "notistack";
import instance from "../services/axiosConfig";
import { User, UserRole } from "../common/types";
import { useAuth } from "../context/AuthProvider";

const settings = [
    {
        name: "Настройки",
        url: "/settings",
        required: false,
    },
    {
        name: "Группа",
        url: "/group",
        required: true,
    },
    {
        name: "История",
        url: "/history",
        required: false,
    },
];

export default function AppBarMain() {
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [master, setMaster] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const { setToken } = useAuth();

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    useEffect(() => {
        instance
            .get<User>("api/user", {
                headers: {
                    Accept: "*/*",
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                if (res.data.role !== UserRole.Slave) {
                    setMaster(true);
                }
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            });
    }, [enqueueSnackbar]);

    const handleLogout = () => {
        authService.logout();
        setToken("");
        window.location.reload();
        enqueueSnackbar("Выход из системы успешен!", { variant: "success" });
    };

    return (
        <AppBar sx={{ boxShadow: "none" }}>
            <Toolbar
                sx={{
                    backgroundColor: "#fff",
                    boxShadow: "inset 0 -1px 0 rgba(0, 0, 0, 0.12)",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Logo />
                    <Typography
                        variant="body1"
                        style={{
                            color: "#5D6570",
                            marginLeft: "16px",
                            fontWeight: 500,
                        }}
                    >
                        HR ASSISTANT
                    </Typography>
                </Box>
                <Box sx={{ flexGrow: 0, marginRight: "8px" }}>
                    <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar alt="Admin" src="/static/images/avatar/2.jpg" />
                        </IconButton>
                    </Tooltip>

                    <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting) =>
                            setting.required ? (
                                master ? (
                                    <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
                                        <Link to={setting.url} style={{ textDecoration: "none" }}>
                                            <Typography textAlign="center" variant="body1" style={{ color: "#5D6570" }}>
                                                {setting.name}
                                            </Typography>
                                        </Link>
                                    </MenuItem>
                                ) : (
                                    <></>
                                )
                            ) : (
                                <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
                                    <Link to={setting.url} style={{ textDecoration: "none" }}>
                                        <Typography textAlign="center" variant="body1" style={{ color: "#5D6570" }}>
                                            {setting.name}
                                        </Typography>
                                    </Link>
                                </MenuItem>
                            )
                        )}
                        <MenuItem key="Выйти" onClick={handleCloseUserMenu}>
                            <Typography
                                textAlign="center"
                                variant="body1"
                                style={{ color: "#5D6570" }}
                                onClick={handleLogout}
                            >
                                Выйти
                            </Typography>
                        </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
}
