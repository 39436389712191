import { Grid, Button, IconButton } from "@mui/material";
import ResponseData, { StatusResponseData } from "../Data/ResponseData";
import React, { useState } from "react";
import { DataAudio, initialDataAudio } from "../Recording";
import instance from "../../services/axiosConfig";
import { useSnackbar } from "notistack";
import { Audio } from "../../common/types";
import EditableLabel from "../Data/EditableLabel";
import { ReactComponent as CleanIcon } from "../../assets/CleanIcon.svg";
import RecordingLoader from "./RecordingLoader";
import { handleError } from "../../common/utils";
import AlertDialog from "../Modals/AlertDialog";
import CustomAudioPlayer from "../Common/CustomAudioPlayer";

interface AudioSourceProps {
    chosenAudio: Audio | null;
    id: number;
    name: string;
    loadingAudio: boolean;
    chosenQuestions: string[];
    blobURL: string;
    answers: string;
    statusAnswers: StatusResponseData;
    savingName: boolean;
    setDataAudio: React.Dispatch<React.SetStateAction<DataAudio>>;
    setChosenAudio: React.Dispatch<React.SetStateAction<Audio | null>>;
    setAudioList: React.Dispatch<React.SetStateAction<Audio[]>>;
    handleSaveAudioName: (id: number, name: string, beforeCallback: () => void, afterCallback: () => void) => void;
}

export default function AudioSource(props: AudioSourceProps) {
    const { enqueueSnackbar } = useSnackbar();

    const [isDeleteAudioDialogOpen, setIsDeleteAudioDialogOpen] = useState(false);

    const buttonDisabled =
        props.loadingAudio ||
        props.statusAnswers === StatusResponseData.waitingForResponse ||
        props.chosenQuestions.length === 0;

    const handleChangeName = (value: string) => {
        props.setDataAudio((data) => ({ ...data, name: value }));
    };

    const handleFetchAnswers = () => {
        props.setDataAudio((data) => ({ ...data, statusAnswers: StatusResponseData.waitingForResponse }));

        instance
            .post(
                "/api/transcribe/call",
                { id: props.id, questions: props.chosenQuestions.join("\n") },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                props.setDataAudio((data) => ({
                    ...data,
                    answers: res.data.answers,
                    statusAnswers: StatusResponseData.dataReceived,
                }));

                enqueueSnackbar("Ответы найдены успешно!", { variant: "success" });
            })
            .catch((err) => {
                props.setDataAudio((data) => ({
                    ...data,
                    statusAnswers: StatusResponseData.responseError,
                }));

                enqueueSnackbar("Произошла ошибка во время поиска ответов!", { variant: "error" });
            });
    };

    const handleDeleteRecording = () => {
        instance
            .delete(`/api/audio/${props.id}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                props.setChosenAudio(null);
                props.setDataAudio(initialDataAudio);
                props.setAudioList((list) => list.filter((a) => a.id !== props.id));

                enqueueSnackbar("Аудио успешно удалено из сохраненных!", {
                    variant: "success",
                });
            })
            .catch((error: any) => {
                handleError(error, enqueueSnackbar, "Не удалось удалить аудио! Попробуйте позже");
            });
    };

    const handleDeleteAnswers = () => {
        props.setDataAudio((data) => ({ ...data, answers: "", statusAnswers: StatusResponseData.idle }));

        enqueueSnackbar("Ответы успешно удалены!", { variant: "success" });
    };

    // don't render any content if there is no chosen audio
    if (props.chosenAudio === null) {
        return <></>;
    }

    return (
        <>
            <Grid item>
                <Grid container wrap="nowrap" alignItems="center">
                    <Grid item flexGrow={1}>
                        <EditableLabel
                            value={props.name}
                            readOnly={false}
                            onChange={(value) => handleChangeName(value)}
                            placeholder={`Дайте название звонку`}
                            onBlur={() =>
                                props.handleSaveAudioName(
                                    props.id,
                                    props.name,
                                    () => props.setDataAudio((data) => ({ ...data, savingName: true })),
                                    () => props.setDataAudio((data) => ({ ...data, savingName: false }))
                                )
                            }
                            loading={props.savingName}
                        />
                    </Grid>
                    <Grid item>
                        <IconButton
                            style={{ margin: 0, padding: "8px" }}
                            aria-label="clean"
                            color="error"
                            onClick={() => setIsDeleteAudioDialogOpen(true)}
                            size="small"
                        >
                            <CleanIcon />
                        </IconButton>
                    </Grid>
                    <AlertDialog
                        open={isDeleteAudioDialogOpen}
                        onClose={() => setIsDeleteAudioDialogOpen(false)}
                        title="Удалить текущую аудиозапись?"
                        description="Вы уверены, что хотите удалить текущую аудиозапись?"
                    >
                        <Button
                            onClick={() => setIsDeleteAudioDialogOpen(false)}
                            autoFocus
                            variant="outlined"
                            color="tertiary"
                        >
                            Не удалять
                        </Button>
                        <Button
                            onClick={() => {
                                handleDeleteRecording();
                                setIsDeleteAudioDialogOpen(false);
                            }}
                            variant="contained"
                            color="error"
                        >
                            Удалить!
                        </Button>
                    </AlertDialog>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container wrap="nowrap" alignItems="center" gap={3}>
                    <Grid item flexGrow={1}>
                        <CustomAudioPlayer blobURL={props.blobURL} />
                    </Grid>
                    <Button
                        variant="contained"
                        style={{
                            margin: "0 0 0 16px",
                        }}
                        onClick={handleFetchAnswers}
                        disabled={buttonDisabled}
                    >
                        Получить ответы
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                {props.loadingAudio && <RecordingLoader text="Загружаем сохраненое аудио..." />}
                <ResponseData
                    status={props.statusAnswers}
                    data={props.answers}
                    handleClean={handleDeleteAnswers}
                    actionName={"Ответы"}
                    actionNameEn={"Answers"}
                    resultName={"Ответы на вопросы"}
                />
            </Grid>
        </>
    );
}
