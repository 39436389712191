import { Button, Divider, Grid, ThemeProvider, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as AmountIcon } from "../assets/AmountIcon.svg";
import { ReactComponent as RegionIcon } from "../assets/RegionIcon.svg";
import { ReactComponent as TypeIcon } from "../assets/TypeIcon.svg";
import instance from "../services/axiosConfig";
import { useSnackbar } from "notistack";
import ResponseData, { StatusResponseData } from "../components/Data/ResponseData";
import SwitchSetting from "../components/Data/SwitchSetting";
import InputData from "../components/Data/InputData";
import InputDataTextField from "../components/Data/InputDataTextField";

export default function SearchCompany() {
    const theme = useTheme();

    const [amountCompanies, setAmountCompanies] = useState<number>(0);
    const [region, setRegion] = useState<string>("");
    const [typeActivity, setTypeActivity] = useState<string>("");

    const [statusSearch, setStatusSearch] = useState<StatusResponseData>(StatusResponseData.idle);
    const [dataSearch, setDataSearch] = useState<string>("");
    const [inputHH, setInputHH] = useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();

    const handleHH = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputHH(event.target.checked);
    };

    const handleSearch = () => {
        setStatusSearch(StatusResponseData.waitingForResponse);
        instance
            .post(
                "/api/search-companies",
                {
                    amount: amountCompanies,
                    region: region,
                    type: typeActivity,
                    hh: inputHH,
                },
                {
                    headers: {
                        Accept: "*/*",
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                enqueueSnackbar("Поиск успешен!", { variant: "success" });
                setDataSearch(res.data);
                setStatusSearch(StatusResponseData.dataReceived);
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
                setStatusSearch(StatusResponseData.responseError);
            });
    };

    const handleClean = () => {
        setStatusSearch(StatusResponseData.idle);
        setDataSearch("");
        setAmountCompanies(0);
        setRegion("");
        setTypeActivity("");
        setInputHH(false);
        enqueueSnackbar("Информация о поиске очищена", { variant: "info" });
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container justifyContent="space-between" style={{ marginBottom: 32 }}>
                <Typography variant="h3" style={{ color: "#5D6570" }}>
                    Поиск компаний
                </Typography>
                <Button
                    variant="contained"
                    style={{ marginRight: 0 }}
                    onClick={handleSearch}
                    disabled={!amountCompanies || !region || !typeActivity}
                >
                    Найти
                </Button>
            </Grid>
            <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 2, sm: 4, md: 8, lg: 12 }}
                style={{ marginBottom: 32 }}
            >
                <Grid item xs={2} sm={4} md={4} lg={4}>
                    <InputData
                        disableRipple={true}
                        label="Количество компаний"
                        inputComponent={
                            <InputDataTextField
                                value={amountCompanies}
                                onChange={(event) => setAmountCompanies(+event.target.value)}
                                placeholder={"Введите количество компаний"}
                                id={"amountCompanies"}
                                type={"number"}
                                resized={false}
                            />
                        }
                        inputIcon={<AmountIcon />}
                        resized={false}
                    />
                </Grid>
                <Grid item xs={2} sm={4} md={4} lg={4}>
                    <InputData
                        disableRipple={true}
                        label="Регион поиска"
                        inputComponent={
                            <InputDataTextField
                                value={region}
                                onChange={(event) => setRegion(event.target.value)}
                                placeholder={"Введите город или страну"}
                                id={"region"}
                                type={""}
                                resized={false}
                            />
                        }
                        inputIcon={<RegionIcon />}
                        resized={false}
                    />
                </Grid>
                <Grid item xs={2} sm={4} md={4} lg={4}>
                    <InputData
                        disableRipple={true}
                        label="Вид деятельности"
                        inputComponent={
                            <InputDataTextField
                                value={typeActivity}
                                onChange={(event) => setTypeActivity(event.target.value)}
                                placeholder={"Введите тип деятельности"}
                                id={"typeActivity"}
                                type={""}
                                resized={false}
                            />
                        }
                        inputIcon={<TypeIcon />}
                        resized={false}
                    />
                </Grid>
            </Grid>
            <Divider style={{ marginBottom: 16 }} />
            <SwitchSetting
                disabled={false}
                label={"Язык поиска HH"}
                description={"Перевести список компаний в язык поиска HH"}
                checked={inputHH}
                setChecked={handleHH}
            />

            <ResponseData
                status={statusSearch}
                data={dataSearch}
                handleClean={handleClean}
                actionName={"Поиск компаний"}
                actionNameEn={"Search"}
            />
        </ThemeProvider>
    );
}
