import * as React from "react";
import { CircularProgress, Grid } from "@mui/material";

export default function CircularIndeterminate() {
    return (
        <Grid container alignItems="center" justifyContent="center" style={{ minHeight: "100vh" }}>
            <CircularProgress />
        </Grid>
    );
}
