import { Button, Grid, styled, ThemeProvider, ToggleButton, Typography, useTheme } from "@mui/material";
import ResponseData, { StatusResponseData } from "../components/Data/ResponseData";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import InputDataTextField from "../components/Data/InputDataTextField";
import InputData from "../components/Data/InputData";
import { ReactComponent as PositionIcon } from "../assets/PositionIcon.svg";
import { ReactComponent as TypeIcon } from "../assets/TypeIcon.svg";
import { ReactComponent as DutiesIcon } from "../assets/DutiesIcon.svg";
import { ReactComponent as TaskIcon } from "../assets/TaskIcon.svg";
import { ReactComponent as StopIcon } from "../assets/StopIcon.svg";
import { ReactComponent as AdditionalIcon } from "../assets/AdditionalIcon.svg";
import instance from "../services/axiosConfig";

const CustomToggleButton = styled(ToggleButton)(() => ({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: "#007FFF",
    },
}));

enum QuestionType {
    General,
    Situational,
    Behavioral,
}

const QuestionTypeString = ["Общие вопросы", "Ситуационные вопросы", "Поведенческие вопросы"];

export default function Questions() {
    const theme = useTheme();
    const [selectedTypes, setSelectedTypes] = useState<QuestionType[]>([QuestionType.General]);
    const [position, setPosition] = useState<string>("");
    const [typeCompanyActivity, setTypeCompanyActivity] = useState<string>("");
    const [duties, setDuties] = useState<string>("");
    const [keyTasks, setKeyTasks] = useState<string>("");
    const [stopFactors, setStopFactors] = useState<string>("");
    const [additional, setAdditional] = useState<string>("");

    const [statusQuestions, setStatusQuestions] = useState<StatusResponseData>(StatusResponseData.idle);
    const [dataQuestions, setDataQuestions] = useState<string>("");

    const { enqueueSnackbar } = useSnackbar();

    const inputItems = [
        {
            label: "Должность",
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setPosition(event.target.value),
            placeholder: "Введите должность",
            id: "position",
            type: "",
            icon: <PositionIcon />,
            required: true,
            value: position,
        },
        {
            label: "Вид деятельности компании",
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setTypeCompanyActivity(event.target.value),
            placeholder: "Введите вид деятельности компании",
            id: "typeCompanyActivity",
            type: "",
            icon: <TypeIcon />,
            required: true,
            value: typeCompanyActivity,
        },
        {
            label: "Обязанности",
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setDuties(event.target.value),
            placeholder: "Введите обязанности",
            id: "duties",
            type: "",
            icon: <DutiesIcon />,
            required: true,
            value: duties,
        },
        {
            label: "Ключевые задачи",
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setKeyTasks(event.target.value),
            placeholder: "Введите ключевые задачи",
            id: "keyTasks",
            type: "",
            icon: <TaskIcon />,
            required: true,
            value: keyTasks,
        },
        {
            label: "Стоп-факторы",
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setStopFactors(event.target.value),
            placeholder: "Введите стоп-факторы",
            id: "stopFactors",
            type: "",
            icon: <StopIcon />,
            required: true,
            value: stopFactors,
        },
        {
            label: "Дополнение",
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setAdditional(event.target.value),
            placeholder: "Введите дополнительную информацию",
            id: "additional",
            type: "",
            icon: <AdditionalIcon />,
            required: false,
            value: additional,
        },
    ];

    const handleQuestionsPost = () => {
        setStatusQuestions(StatusResponseData.waitingForResponse);

        instance
            .post(
                "/api/questions",
                {
                    types: selectedTypes,
                    position: position,
                    type_of_activity: typeCompanyActivity,
                    duties: duties,
                    tasks: keyTasks,
                    stop: stopFactors,
                    additional: additional,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                enqueueSnackbar("Вопросы получены успешно!", {
                    variant: "success",
                });
                setDataQuestions(res.data);
                setStatusQuestions(StatusResponseData.dataReceived);
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
                setStatusQuestions(StatusResponseData.responseError);
            });
    };

    const handleClean = () => {
        setSelectedTypes([QuestionType.General]);
        setPosition("");
        setTypeCompanyActivity("");
        setDuties("");
        setKeyTasks("");
        setStopFactors("");
        setAdditional("");
        setDataQuestions("");
        setStatusQuestions(StatusResponseData.idle);
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container justifyContent="space-between" style={{ marginBottom: 16 }}>
                <Typography variant="h3" style={{ color: "#5D6570" }}>
                    Вопросы кандидату
                </Typography>

                <Button
                    variant="contained"
                    style={{ marginRight: 0 }}
                    onClick={handleQuestionsPost}
                    disabled={
                        inputItems.filter((item) => item.required).some((item) => !item.value) ||
                        selectedTypes.length === 0
                    }
                >
                    Сформировать
                </Button>
            </Grid>

            <Grid style={{ marginBottom: 16 }}>
                {QuestionTypeString.map((v, i) => {
                    return (
                        <CustomToggleButton
                            size="small"
                            style={{ borderRadius: 12, marginLeft: 0, padding: "4px 8px 4px 8px" }}
                            value="v"
                            selected={selectedTypes.includes(i)}
                            onChange={() => {
                                if (selectedTypes.includes(i)) {
                                    setSelectedTypes((ss) => ss.filter((s) => s !== i));
                                } else {
                                    setSelectedTypes((ss) => [...ss, i]);
                                }
                            }}
                        >
                            {v}
                        </CustomToggleButton>
                    );
                })}
            </Grid>

            <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 2, sm: 4, md: 8, lg: 12 }}
                style={{ marginBottom: 32 }}
            >
                {inputItems.map((item) => (
                    <Grid item xs={2} sm={4} md={4} lg={4}>
                        <InputData
                            disableRipple={true}
                            label={item.label}
                            inputComponent={
                                <InputDataTextField
                                    value={item.value}
                                    onChange={item.onChange}
                                    placeholder={item.placeholder}
                                    id={item.id}
                                    type={item.type}
                                    resized={true}
                                />
                            }
                            inputIcon={item.icon}
                            resized={true}
                        />
                    </Grid>
                ))}
            </Grid>
            {/* <Divider style={{ marginBottom: 16 }} /> */}

            {/*<SwitchSetting*/}
            {/*    disabled={false}*/}
            {/*    label={"Язык поиска HH"}*/}
            {/*    description={"Перевести список компаний в язык поиска HH"}*/}
            {/*    checked={inputHH}*/}
            {/*    setChecked={handleHH}*/}
            {/*/>*/}

            <ResponseData
                status={statusQuestions}
                data={dataQuestions}
                handleClean={handleClean}
                actionName={"Формирование вопросов"}
                actionNameEn={"Get questions"}
            />
        </ThemeProvider>
    );
}
