import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";

interface AlertDialogProps {
    open: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
    description?: string;
}

export default function AlertDialog(props: AlertDialogProps) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby={props.description && "alert-dialog-description"}
            sx={{ width: "30em", mx: "auto" }}
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            {props.description && (
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{props.description}</DialogContentText>
                </DialogContent>
            )}
            <DialogActions sx={{ justifyContent: "center", pb: 2 }}>{props.children}</DialogActions>
        </Dialog>
    );
}
