import React, { useMemo } from "react";
import { MRT_ColumnDef, MaterialReactTable } from "material-react-table";
import { HistoryQuestions } from "../../common/types";
import { getTimeString } from "../../common/utils";

function getTypeString(types: number[]) {
    const typeQuestionsName = ["Общие вопросы", "Ситуационные вопросы", "Поведенческие вопросы"];

    var retString = "";
    types.forEach((t, i) => {
        retString += typeQuestionsName[t];
        if (i !== types.length) {
            retString += ", ";
        }
    });
    return retString;
}

interface InputProps {
    data: [];
}

export default function TableQuestions(props: InputProps) {
    const columns = useMemo<MRT_ColumnDef<HistoryQuestions>[]>(
        () => [
            {
                accessorFn: (row) => row.owner.username,
                id: "owner.username",
                header: "Username",
                enableHiding: false,
                size: 100,
            },
            {
                accessorFn: (row) => getTimeString(row.created_at),
                id: "created_at",
                header: "Created at",
                enableHiding: false,
                size: 100,
            },
            {
                accessorFn: (row) => getTypeString(row.type),
                id: "type",
                header: "Type",
                size: 150,
            },
            {
                accessorFn: (row) =>
                    `Должность: ${row.position}, Вид деятельности: ${row.type_of_activity}, Обязанности: ${row.duties}, ` +
                    `Ключевые задачи: ${row.tasks}, Стоп-факторы: ${row.stop}, Дополнение: ${row.additional}`,
                id: "input_data",
                header: "Input data",
                size: 300,
            },
            {
                accessorKey: "data",
                header: "Questions",
                size: 600,
            },
        ],
        []
    );
    return (
        <MaterialReactTable
            columns={columns}
            data={props.data}
            // enableColumnActions={false}
            // enableColumnFilters={false}
            // enablePagination={false}
            // enableSorting={false}
        />
    );
}
