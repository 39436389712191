import { Grid, Switch, Typography } from "@mui/material";
import React from "react";

interface InputProps {
    label: string;
    description: string;
    disabled: boolean;
    checked: boolean;
    setChecked: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function SwitchSetting(props: InputProps) {
    return (
        <Grid container justifyContent="space-between" style={{ marginBottom: 16 }}>
            <Grid item>
                <Typography variant="h5" style={{ color: "#5D6570" }}>
                    {props.label}
                </Typography>
                <Typography variant="body1" style={{ color: "#949698" }}>
                    {props.description}
                </Typography>
            </Grid>
            <Grid item>
                <Switch disabled={props.disabled} checked={props.checked} onChange={props.setChecked}></Switch>
            </Grid>
        </Grid>
    );
}
