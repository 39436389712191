import instance from "./axiosConfig";

class AuthService {
    async login(username: string, password: string) {
        return instance
            .post(
                "/api/sign-in",
                {
                    username: username,
                    password: password,
                },
                {
                    headers: {
                        Accept: "*/*",
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                if (response.data.token) {
                    instance.defaults.headers.common["Authorization"] = response.data.token;
                    localStorage.setItem("token", response.data.token);

                    return response.data.token;
                }
                return "";
            })
            .catch((error) => {
                return error;
            });
    }

    logout() {
        delete instance.defaults.headers.common["Authorization"];
        localStorage.removeItem("token");
    }

    async register(username: string, password: string, confirmationPassword: string) {
        return instance
            .post(
                "/api/sign-up",
                {
                    username: username,
                    password: password,
                    confirmation_password: confirmationPassword,
                },
                {
                    headers: {
                        Accept: "*/*",
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error;
            });
    }

    async getAuth(token: string) {
        instance.defaults.headers.common["Authorization"] = token;
        return instance
            .get("/api/auth", {
                headers: {
                    Accept: "*/*",
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                localStorage.setItem("token", token);
                return response.status === 200;
            })
            .catch((error) => {
                // console.log(error);
                delete instance.defaults.headers.common["Authorization"];
                localStorage.removeItem("token");
                return false;
            });
    }
}

export default new AuthService();
