import { TextField, IconButton, InputAdornment, CircularProgress } from "@mui/material";
import { ReactComponent as EditIcon } from "../../assets/EditIcon.svg";
import { useState, useRef } from "react";

interface InputProps {
    value: string;
    onChange: (newValue: string) => void;
    readOnly: boolean;
    placeholder: string;
    onBlur?: () => void;
    loading?: boolean;
}

export default function EditableLabel(props: InputProps) {
    const [hovering, setHovering] = useState(true);
    const [focused, setFocused] = useState(false);
    const textFieldRef = useRef<HTMLInputElement>(null);

    const handleEditClick = () => {
        textFieldRef.current?.focus();
        setFocused(true);
    };

    const editIconButton = (
        <IconButton
            style={{ margin: "0", padding: "4px" }}
            onClick={handleEditClick}
            aria-label="clean"
            color="primary"
            size="small"
        >
            <EditIcon />
        </IconButton>
    );

    return (
        <TextField
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            inputRef={textFieldRef}
            InputProps={{
                readOnly: props.readOnly,
                disableUnderline: props.readOnly || (!hovering && !focused),
                onMouseEnter: () => setHovering(true),
                onMouseLeave: () => setHovering(false),
                onFocus: () => setFocused(true),
                onBlur: () => {
                    setFocused(false);
                    props.onBlur?.();
                },
                startAdornment: props.readOnly ? null : (
                    <InputAdornment position="start">
                        {props.loading !== undefined && props.loading ? (
                            <CircularProgress color="primary" size={30} />
                        ) : (
                            editIconButton
                        )}
                    </InputAdornment>
                ),
            }}
            inputProps={{
                style: {
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                    fontWeight: 400,
                    fontSize: "1.5rem",
                    lineHeight: 1.334,
                    letterSpacing: "0em",
                    color: "rgb(93, 101, 112)",
                    padding: 0,
                    margin: 0,
                },
            }}
            variant="standard"
            fullWidth
            style={{ padding: 0 }}
            placeholder={props.placeholder}
        />
    );
}
