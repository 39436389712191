import { Button, Grid, SvgIcon, Typography } from "@mui/material";
import React, { ReactNode } from "react";

interface InputProps {
    disableRipple: boolean;
    label: string;
    inputComponent: ReactNode;
    inputIcon: ReactNode;
    resized: boolean;
}

export default function InputData(props: InputProps) {
    return (
        <Button
            component="label"
            variant="outlined"
            disableRipple={props.disableRipple}
            style={{ display: "block", margin: "0 auto", padding: "16px" }}
        >
            <Grid container direction="row" style={{ padding: 8, paddingBottom: props.resized ? 0 : 8 }}>
                <Grid item style={{ height: 32, marginRight: 8 }}>
                    <SvgIcon style={{ width: 32, height: 32 }} viewBox="0 0 32 32">
                        {props.inputIcon}
                    </SvgIcon>
                </Grid>
                <Grid item style={{ height: props.resized ? "auto" : 32, flexGrow: 1 }}>
                    <Typography
                        fontSize="16px"
                        lineHeight="16px"
                        fontWeight="bold"
                        style={{ color: "#5D6570", textTransform: "none" }}
                    >
                        {props.label}
                    </Typography>
                    {props.inputComponent}
                </Grid>
            </Grid>
        </Button>
    );
}
