import { Autocomplete, FormControl, Grid, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Audio } from "../common/types";
import { useSnackbar } from "notistack";
import instance from "../services/axiosConfig";
import { StatusResponseData } from "./Data/ResponseData";
import AudioSource from "./RecordingUI/AudioSource";
import MicroSource from "./RecordingUI/MicroSource";
import YouTubeSource from "./RecordingUI/YouTubeSource";
import { handleError } from "../common/utils";

const initialDataCommon: RecordingCommon = {
    id: -1,
    name: "",
    answers: "",
    statusAnswers: StatusResponseData.idle,
};

export const initialDataAudio: DataAudio = {
    ...initialDataCommon,
    loadingAudio: false,
    blobData: new Blob(),
    blobURL: "",
    savingName: false,
};

export const initialDataMicro: DataMicro = {
    ...initialDataCommon,
    savingRecording: false,
    blobData: new Blob(),
    blobURL: "",
    savingName: false,
};

export const initialDataYouTube: DataYouTube = {
    ...initialDataCommon,
    youTubeURL: "",
    showPlayer: true,
};

const enum RecordingSource {
    Micro,
    YouTube,
    Audio,
}

interface RecordingCommon {
    id: number;
    name: string;
    answers: string;
    statusAnswers: StatusResponseData;
}

export interface DataAudio extends RecordingCommon {
    loadingAudio: boolean;
    blobData: Blob;
    blobURL: string;
    savingName: boolean;
}

export interface DataMicro extends RecordingCommon {
    savingRecording: boolean;
    blobData: Blob;
    blobURL: string;
    savingName: boolean;
}

export interface DataYouTube extends RecordingCommon {
    youTubeURL: string;
    showPlayer: boolean;
}

interface RecordingProps {
    chosenQuestions: string[];
}

export default function Recording(props: RecordingProps) {
    // common states and global vars
    const { enqueueSnackbar } = useSnackbar();
    const [recordingSource, setRecordingSource] = useState(RecordingSource.Micro);

    // source: micro
    const [dataMicro, setDataMicro] = useState<DataMicro[]>([]);

    // source: youtube
    const [dataYouTube, setDataYouTube] = useState<DataYouTube>(initialDataYouTube);

    // source: audio from db
    const [audioList, setAudioList] = useState<Audio[]>([]);
    const [dataAudio, setDataAudio] = useState<DataAudio>(initialDataAudio);
    const [chosenAudio, setChosenAudio] = useState<Audio | null>(null);

    const handleChooseAudio = (a: Audio | null) => {
        setChosenAudio(a);

        if (a === null) {
            return setDataAudio(initialDataAudio);
        }

        setDataAudio({
            ...initialDataAudio,
            id: a.id,
            name: a.name,
            loadingAudio: true,
        });
        setRecordingSource(RecordingSource.Audio);

        // GET AUDIO BLOB request on choosing audio
        instance
            .get(`/api/audio/${a.id}`, {
                responseType: "arraybuffer",
            })
            .then((res) => {
                let blob = new Blob([res.data], { type: "audio/mp3" });
                setDataAudio((data) => ({
                    ...data,
                    blobData: blob,
                    blobURL: URL.createObjectURL(blob),
                }));
            })
            .catch((error) => {
                enqueueSnackbar("Не удалось скачать аудиофайл: " + error.response.data.error, {
                    variant: "error",
                });
            })
            .finally(() => {
                setDataAudio((data) => ({ ...data, loadingAudio: false }));
            });
    };

    const handleFetchAudioList = () => {
        instance
            .get<Audio[]>(`/api/audios`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                setAudioList(res.data);
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            });
    };

    const handleSaveAudioName = (id: number, name: string, beforeCallback: () => void, afterCallback: () => void) => {
        const audio = audioList.find((a) => a.id === id);
        if (audio === undefined) {
            return enqueueSnackbar("Произошла ошибка при попытке переименовать аудио!");
        } else if (audio.name === name) {
            return;
        }

        beforeCallback();
        instance
            .put(
                `/api/audio/${id}`,
                { name },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                setAudioList((list) => list.map((a) => (a.id === id ? { ...a, name } : a)));
                if (recordingSource === RecordingSource.Audio) {
                    setChosenAudio((audio) => (audio !== null ? { ...audio, name } : null));
                }

                enqueueSnackbar("Новое название сохранено", {
                    variant: "info",
                });
            })
            .catch((error) => {
                handleError(error, enqueueSnackbar, "Не удалось сохранить новое название аудио!");
            })
            .finally(afterCallback);
    };

    // GET AUDIOS on initial render
    useEffect(handleFetchAudioList, [enqueueSnackbar]);

    return (
        <Grid item sx={{ width: "50%", marginLeft: "32px", marginBottom: "32px" }}>
            {/* Autocomplete and select components */}
            <Grid container spacing={2} style={{ marginBottom: 32 }}>
                <Grid item xs={8}>
                    <Autocomplete
                        disablePortal
                        id="audio-autocomplete"
                        options={audioList}
                        getOptionLabel={(a) => {
                            return a.name;
                        }}
                        value={chosenAudio}
                        onChange={(event: any, a: Audio | null) => handleChooseAudio(a)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Выбор сохраненного интервью"
                                onClick={() => {
                                    if (audioList.length === 0) {
                                        enqueueSnackbar("Сохраненных интервью нет!", {
                                            variant: "info",
                                        });
                                    } else if (dataAudio.statusAnswers === StatusResponseData.waitingForResponse) {
                                        enqueueSnackbar("Пожалуйста подождите пока завершится поиск ответов!", {
                                            variant: "error",
                                        });
                                    }
                                }}
                            />
                        )}
                        readOnly={
                            audioList.length === 0 || dataAudio.statusAnswers === StatusResponseData.waitingForResponse
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel id="choose-recording-source">Источник аудио</InputLabel>
                        <Select
                            labelId="choose-recording-source"
                            id="choose-recording-source"
                            value={recordingSource}
                            label="Источник аудио"
                            onChange={(e) => setRecordingSource(e.target.value as RecordingSource)}
                        >
                            <MenuItem value={RecordingSource.Micro}>Записать с микрофона</MenuItem>
                            <MenuItem value={RecordingSource.YouTube}>Ссылка на YouTube аудио</MenuItem>
                            <MenuItem value={RecordingSource.Audio}>Сохраненное интервью</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {/* Main audio sources */}
            <Grid container direction="column" wrap="nowrap" spacing={5} style={{ marginBottom: 32 }}>
                {recordingSource === RecordingSource.Micro && (
                    <MicroSource
                        dataMicro={dataMicro}
                        setDataMicro={setDataMicro}
                        chosenQuestions={props.chosenQuestions}
                        handleFetchAudioList={handleFetchAudioList}
                        handleSaveAudioName={handleSaveAudioName}
                    />
                )}
                {recordingSource === RecordingSource.Audio && (
                    <AudioSource
                        {...dataAudio}
                        chosenAudio={chosenAudio}
                        chosenQuestions={props.chosenQuestions}
                        setDataAudio={setDataAudio}
                        setChosenAudio={setChosenAudio}
                        setAudioList={setAudioList}
                        handleSaveAudioName={handleSaveAudioName}
                    />
                )}
                {recordingSource === RecordingSource.YouTube && (
                    <YouTubeSource
                        {...dataYouTube}
                        setDataYouTube={setDataYouTube}
                        chosenQuestions={props.chosenQuestions}
                        handleFetchAudioList={handleFetchAudioList}
                    />
                )}
            </Grid>
        </Grid>
    );
}
