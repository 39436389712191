import { Backdrop, Box, Button, Fade, Modal, Typography } from "@mui/material";

interface InputProps {
    open: boolean;
    onClose: () => void;
    title: string;
    description: string;
    buttonText: string;
    buttonHandle: () => void;
}

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: 0,
};

export default function ConfirmationModal(props: InputProps) {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.open}
            onClose={props.onClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={props.open}>
                <Box sx={style}>
                    <Typography variant="h5" style={{ color: "#5D6570" }}>
                        {props.title}
                    </Typography>
                    <Typography variant="body1" style={{ color: "#949698", marginTop: 16 }}>
                        {props.description}
                    </Typography>
                    <Button style={{ marginTop: 16 }} variant="contained" onClick={props.buttonHandle} color="error">
                        {props.buttonText}
                    </Button>
                </Box>
            </Fade>
        </Modal>
    );
}
