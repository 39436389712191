import { Grid, ListItem, ListItemIcon, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

interface NavigationProps {
    text: string;
    path: string;
    icon: ReactNode;
    isActive: boolean;
}

export default function NavigationMenuItem(props: NavigationProps) {
    return (
        <ListItem
            key={props.text}
            disablePadding
            component={Link}
            to={props.path}
            sx={{
                display: "block",
                justifyContent: "initial",
            }}
        >
            <Grid style={{ padding: "0px 16px 8px 16px" }}>
                <Grid
                    container
                    direction="row"
                    style={{
                        backgroundColor: props.isActive ? "#F0F7FF" : "",
                        borderRadius: 8,
                        padding: "4px 8px",
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 3,
                            justifyContent: "center",
                        }}
                        style={{
                            color: props.isActive ? "#007FFF" : "#5D6570",
                            marginRight: 0,
                        }}
                    >
                        {props.icon}
                    </ListItemIcon>
                    <Typography
                        variant="body1"
                        fontWeight={props.isActive ? "bold" : 500}
                        color={props.isActive ? "#0B0D0E" : "#5D6570"}
                    >
                        {props.text}
                    </Typography>
                </Grid>
            </Grid>
        </ListItem>
    );
}
