import React, { useMemo } from "react";
import { MRT_ColumnDef, MaterialReactTable } from "material-react-table";
import { HistoryCall } from "../../common/types";
import { getTimeString } from "../../common/utils";

interface InputProps {
    data: HistoryCall[];
}
export default function TableCall(props: InputProps) {
    const columns = useMemo<MRT_ColumnDef<HistoryCall>[]>(
        () => [
            {
                accessorFn: (row) => row.owner.username,
                id: "owner.username",
                header: "Username",
                enableHiding: false,
                size: 100,
            },
            {
                accessorFn: (row) => getTimeString(row.created_at),
                id: "created_at",
                header: "Created at",
                enableHiding: false,
                size: 100,
            },
            {
                accessorKey: "questions",
                header: "Questions",
                size: 300,
            },
            {
                accessorKey: "transcribation",
                header: "Transcribation",
                size: 400,
            },
            {
                accessorKey: "answers",
                header: "Answers",
                size: 400,
            },
        ],
        []
    );

    return (
        <MaterialReactTable
            columns={columns}
            data={props.data}
            // enableColumnActions={false}
            // enableColumnFilters={false}
            // enablePagination={false}
            // enableSorting={false}
        />
    );
}
