import React, { useMemo } from "react";
import { MRT_ColumnDef, MaterialReactTable } from "material-react-table";
import { HistoryTranscribation } from "../../common/types";
import { getTimeString } from "../../common/utils";

interface InputProps {
    data: HistoryTranscribation[];
}
export default function TableTranscribation(props: InputProps) {
    const columns = useMemo<MRT_ColumnDef<HistoryTranscribation>[]>(
        () => [
            {
                accessorFn: (row) => row.owner.username,
                id: "owner.username",
                header: "Username",
                enableHiding: false,
                size: 100,
            },
            {
                accessorFn: (row) => getTimeString(row.created_at),
                id: "created_at",
                header: "Created at",
                enableHiding: false,
                size: 100,
            },
            {
                accessorKey: "input_type",
                header: "Type",
                size: 100,
            },
            {
                accessorKey: "transcribation",
                header: "Transcribation",
                size: 600,
            },
        ],
        []
    );

    return (
        <MaterialReactTable
            columns={columns}
            data={props.data}
            // enableColumnActions={false}
            // enableColumnFilters={false}
            // enablePagination={false}
            // enableSorting={false}
        />
    );
}
