import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import "typeface-roboto";
import RouterMain from "./routes/RouterMain";
import { SnackbarProvider } from "notistack";
import AuthProvider from "./context/AuthProvider";

const theme = createTheme({
    palette: {
        primary: {
            main: "#007FFF",
        },
        error: {
            main: "#FF0000",
        },
        tertiary: { main: "#000000", contrastText: "#FFFFFF" },
    },
    components: {
        // MuiTextField: {
        //     styleOverrides: {
        //         root: {
        //             '--TextField-brandBorderColor': '#007FFF',
        //             '--TextField-brandBackgroundColor': '#007FFF0A',
        //             '--TextField-brandBorderFocusedColor': '#007FFF',
        //             '& label.Mui-focused': {
        //                 color: 'var(--TextField-brandBorderFocusedColor)',
        //             },
        //         },
        //     },
        // },
        // MuiOutlinedInput: {
        //     styleOverrides: {
        //         notchedOutline: {
        //             borderColor: 'var(--TextField-brandBorderColor)',
        //         },
        //         root: {
        //             [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
        //                 borderColor: 'var(--TextField-brandBorderColor)',
        //                 backgroundColor: 'var(--TextField-brandBackgroundColor)',
        //                 transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
        //             },
        //             [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
        //                 borderColor: 'var(--TextField-brandBorderColor)',
        //                 borderWidth: 2
        //             },
        //         },
        //     },
        // },
    },
});

declare module "@mui/material/styles" {
    interface Palette {
        tertiary: Palette["primary"];
    }
    interface PaletteOptions {
        tertiary: PaletteOptions["primary"];
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        tertiary: true;
    }
}

function App() {
    return (
        <div style={{ minHeight: "100vh" }}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3}>
                    <AuthProvider>
                        <RouterMain />
                    </AuthProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;
