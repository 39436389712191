import React, { ChangeEvent, useState } from "react";
import { Button, Typography, Grid, ThemeProvider, useTheme, Divider, Box } from "@mui/material";
import { ReactComponent as AttachIcon } from "../assets/AttachIcon.svg";
import { ReactComponent as YouTubeIcon } from "../assets/YouTubeIcon.svg";
import "react-h5-audio-player/lib/styles.css";
import instance from "../services/axiosConfig";
import { useSnackbar } from "notistack";
import ResponseData, { StatusResponseData } from "../components/Data/ResponseData";
import SwitchSetting from "../components/Data/SwitchSetting";
import InputData from "../components/Data/InputData";
import InputDataTextField from "../components/Data/InputDataTextField";
import ReactPlayer from "react-player";
import CustomAudioPlayer from "../components/Common/CustomAudioPlayer";
import ExpandMore from "../components/ExpandMore";
import { VisuallyHiddenInput } from "../common/styled";

enum Task {
    file,
    youtube,
}

function getAudioType(task: Task) {
    switch (task) {
        case Task.file:
            return "Загруженный файл";
        case Task.youtube:
            return "Аудиоряд с YouTube";
    }
}

export default function Transcribation() {
    const theme = useTheme();
    const [youTubeURL, setYouTubeURL] = useState<string>("");
    const [dataTranscribation, setDataTranscribation] = useState<string>("");
    const [inputTimeline, setInputTimeline] = useState<boolean>(false);
    const [inputDiarization, setInputDiarization] = useState<boolean>(false);
    const [audioFile, setAudioFile] = useState<File>();
    const [lastTask, setLastTask] = useState<Task>();
    const [statusTranscription, setStatusTranscription] = useState<StatusResponseData>(StatusResponseData.idle);
    const [showMoreSettings, setShowMoreSettings] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleTimeline = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputTimeline(event.target.checked);
    };
    const handleDiarization = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputDiarization(event.target.checked);
    };

    const handleUploadAudioFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setAudioFile(e.target.files[0]);
            setLastTask(Task.file);
            setShowMoreSettings(true);
            setStatusTranscription(StatusResponseData.taskSelected);
            enqueueSnackbar("Файл загружен", { variant: "info" });
        }
    };

    const handleTranscribePost = (formData: FormData) => {
        setStatusTranscription(StatusResponseData.waitingForResponse);
        instance
            .post("/api/transcribe", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
                enqueueSnackbar("Транскрибация успешна!", {
                    variant: "success",
                });
                setStatusTranscription(StatusResponseData.dataReceived);
                setDataTranscribation(res.data.result);
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
                setStatusTranscription(StatusResponseData.responseError);
            });
    };

    const handleAudioFile = () => {
        if (!audioFile) {
            console.log("Error: empty file");
            return;
        }
        const formData = new FormData();
        formData.append("url", "");
        formData.append("file", audioFile);
        formData.append("timeline", inputTimeline ? "yes" : "no");
        formData.append("input_type", "File");

        handleTranscribePost(formData);
    };

    const handleYouTube = () => {
        const formData = new FormData();
        formData.append("url", youTubeURL);
        formData.append("file", "");
        formData.append("timeline", inputTimeline ? "yes" : "no");
        formData.append("input_type", "YouTube");

        handleTranscribePost(formData);
    };

    const handleTranscribe = () => {
        setShowMoreSettings(false);
        switch (lastTask) {
            case Task.file:
                handleAudioFile();
                break;
            case Task.youtube:
                handleYouTube();
                break;
            default:
                console.log("error task");
        }
    };

    const handleClean = () => {
        setStatusTranscription(StatusResponseData.idle);
        setDataTranscribation("");
        setLastTask(undefined);
        setAudioFile(undefined);
        setYouTubeURL("");
        setInputTimeline(false);
        setInputDiarization(false);
        enqueueSnackbar("Данные транскрибации очищены", { variant: "info" });
    };

    const player = {
        [Task.file]: audioFile ? <CustomAudioPlayer blobURL={URL.createObjectURL(audioFile)} /> : <></>,
        [Task.youtube]: youTubeURL ? (
            <ReactPlayer
                style={{
                    marginTop: 16,
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
                url={youTubeURL}
                controls={true}
            />
        ) : (
            <></>
        ),
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container justifyContent="space-between" style={{ marginBottom: 32 }}>
                <Typography variant="h3" style={{ color: "#5D6570" }}>
                    Транскрибация
                </Typography>
                <Button
                    variant="contained"
                    style={{ marginRight: 0 }}
                    onClick={handleTranscribe}
                    disabled={
                        statusTranscription === StatusResponseData.idle ||
                        statusTranscription === StatusResponseData.waitingForResponse
                    }
                >
                    Транскрибировать
                </Button>
            </Grid>

            <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 2, sm: 4, md: 8, lg: 12 }}
                style={{ marginBottom: 32 }}
            >
                <Grid item xs={2} sm={4} md={4} lg={6}>
                    <InputData
                        disableRipple={true}
                        label="Загрузить файл"
                        inputComponent={
                            <Typography
                                fontSize="12px"
                                lineHeight="16px"
                                fontWeight="bold"
                                style={{
                                    color: "#949698",
                                    textTransform: "none",
                                }}
                            >
                                {lastTask === Task.file ? audioFile?.name : "Нажмите, чтобы выбрать файл"}
                                <VisuallyHiddenInput type="file" onChange={handleUploadAudioFile} />
                            </Typography>
                        }
                        inputIcon={<AttachIcon />}
                        resized={false}
                    />
                </Grid>
                <Grid item xs={2} sm={4} md={4} lg={6}>
                    <InputData
                        disableRipple={true}
                        label="Ссылка YouTube"
                        inputComponent={
                            <InputDataTextField
                                value={youTubeURL}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setYouTubeURL(event.target.value);
                                    setLastTask(Task.youtube);
                                    setShowMoreSettings(true);
                                    setStatusTranscription(StatusResponseData.taskSelected);
                                }}
                                placeholder={"Введите ссылку YouTube"}
                                id={"youTubeLink"}
                                type={""}
                                resized={false}
                            />
                        }
                        inputIcon={<YouTubeIcon />}
                        resized={false}
                    />
                </Grid>
            </Grid>
            {/* <Divider style={{ marginBottom: 16 }} /> */}
            <ExpandMore
                showMore={showMoreSettings}
                setShowMore={setShowMoreSettings}
                title="дополнительные настройки и предпросмотр"
                duration={0.1}
            >
                <Box style={{ marginTop: 8 }}>
                    <SwitchSetting
                        disabled={false}
                        label={"Интервалы"}
                        description={"Разбиение аудио на временные интервалы"}
                        checked={inputTimeline}
                        setChecked={handleTimeline}
                    />
                    <SwitchSetting
                        disabled={true}
                        label={"Диаризация"}
                        description={"Разбиение диалога по ролям"}
                        checked={inputDiarization}
                        setChecked={handleDiarization}
                    />

                    {lastTask !== undefined ? (
                        <Grid>
                            <Divider style={{ marginBottom: 16 }} />
                            <Grid container direction="row">
                                <Typography variant="h5" style={{ color: "#5D6570" }}>
                                    Текущий тип аудио:
                                </Typography>
                                <Typography
                                    variant="h5"
                                    style={{ color: "#5D6570", marginLeft: "8px" }}
                                    fontWeight="bold"
                                >
                                    {getAudioType(lastTask)}
                                </Typography>
                            </Grid>
                            {/*<Divider style={{marginBottom: 16, marginTop: 16}} />*/}
                            {player[lastTask]}
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Box>
            </ExpandMore>

            <ResponseData
                status={statusTranscription}
                data={dataTranscribation}
                handleClean={handleClean}
                actionName={"Транскрибация"}
                actionNameEn={"Transcribation"}
            />
        </ThemeProvider>
    );
}
