import React, { useState } from "react";
import { Grid, ThemeProvider, useTheme, Divider } from "@mui/material";
import Recording from "../components/Recording";
import Templates from "../components/Templates";

export default function Calling() {
    const theme = useTheme();
    const [chosenQuestions, setChosenQuestions] = useState<string[]>([]);

    return (
        <ThemeProvider theme={theme}>
            <Grid container flexWrap={"nowrap"}>
                <Templates setChosenQuestions={setChosenQuestions} />
                <Divider orientation="vertical" flexItem />
                <Recording chosenQuestions={chosenQuestions} />
            </Grid>
        </ThemeProvider>
    );
}
