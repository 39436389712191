import { TextField } from "@mui/material";
import React from "react";

interface InputProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
    id: string;
    type: string;
    resized: boolean;
    value: string | number;
}

export default function InputDataTextField(props: InputProps) {
    return (
        <TextField
            maxRows={props.resized ? 5 : 1}
            minRows={1}
            multiline={props.resized}
            fullWidth
            size="small"
            placeholder={props.placeholder}
            id={props.id}
            type={props.type}
            variant="standard"
            onChange={props.onChange}
            style={{ padding: 0, stroke: "none", fontSize: "12px", lineHeight: "16px", fontWeight: "bold" }}
            value={props.value || ""}
            InputProps={{
                disableUnderline: true,
            }}
            inputProps={{
                style: {
                    padding: 0,
                    color: "#949698",
                },
            }}
        />
    );
}
