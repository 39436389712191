import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import instance from "../services/axiosConfig";
import authService from "../services/authService";

export interface AuthContextType {
    token: string;
    setToken: (token: string) => void;
}

export const AuthContext = createContext<AuthContextType>({
    token: "",
    setToken: () => {},
});

export default function AuthProvider({ children }: { children: ReactNode }) {
    const [_token, _setToken] = useState(localStorage.getItem("token") ?? "");

    const setToken = (token: string) => {
        _setToken(token);
    };

    useEffect(() => {
        if (_token !== "") {
            authService.getAuth(_token).then((res) => {
                if (!res) {
                    setToken("");
                }
            });
        } else {
            delete instance.defaults.headers.common["Authorization"];
            localStorage.removeItem("token");
        }
    }, [_token]);

    const contextValue = useMemo<AuthContextType>(
        () => ({
            token: _token,
            setToken: setToken,
        }),
        [_token]
    );

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
    return useContext(AuthContext);
};
