import { Box, Button, styled } from "@mui/material";
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from "react";
import { ReactComponent as ExpandMoreIcon } from "../assets/ExpandMoreIcon.svg";

interface InputProps {
    showMore: boolean;
    setShowMore: Dispatch<SetStateAction<boolean>>;
    title: string;
    duration: number;
    children: ReactNode;
}

const HiddenDiv = styled("div")({
    height: 0,
    overflow: "hidden",
});

export default function ExpandMore(props: InputProps) {
    const [contentDefault, setContentDefault] = useState<any>();
    const [contentHeight, setContentHeight] = useState<number>(0);
    const contentRef = useRef<any>(null);

    const [deg, setDeg] = useState<number>(0);
    const [title, setTitle] = useState<string>("");

    useEffect(() => {
        const { current } = contentRef;
        if (current !== null) {
            setContentDefault(current.offsetHeight);
            setContentHeight(current.offsetHeight);
        }

        if (props.showMore) {
            setContentHeight(contentDefault);
            setDeg(180);
            setTitle("Скрыть");
        } else {
            setContentHeight(0);
            setDeg(0);
            setTitle("Показать");
        }
    }, [contentDefault, props.showMore]);

    return (
        <>
            <Button
                variant="outlined"
                style={{
                    marginLeft: 0,
                    justifyContent: "flex-start",
                }}
                fullWidth
                onClick={() => props.setShowMore((show) => !show)}
            >
                <ExpandMoreIcon style={{ transform: `rotate(${deg}deg)`, transition: `all ${props.duration}s` }} />
                {title} {props.title}
            </Button>
            <HiddenDiv style={{ height: contentHeight, transition: `all ${props.duration}s` }}>
                <Box style={{ paddingBottom: 8 }} ref={contentRef}>
                    {props.children}
                </Box>
            </HiddenDiv>
        </>
    );
}
