import axios from "axios";

const instance = axios.create({
    baseURL: "https://hrassistant.ru",
});

// instance.defaults.headers.common["Accept"] = "*/*";
// instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*/*";
// instance.defaults.headers.common["Content-Type"] = "application/json";

export default instance;
