import { Box, Grid, ThemeProvider, Typography, useTheme } from "@mui/material";

export default function NotFound() {
    const theme = useTheme();

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    // minHeight: "calc(100vh - 104px)",
                }}
            >
                <Grid>
                    <Typography variant="h4" style={{ color: "#FF0000", textAlign: "center" }}>
                        Ошибка 404
                    </Typography>
                    <Typography variant="h5" style={{ color: "#5D6570", textAlign: "center" }}>
                        Извините, страница не найдена.
                    </Typography>
                </Grid>
            </Box>
        </ThemeProvider>
    );
}
