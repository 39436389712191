enum StatusRecorder {
    idle,
    inited,
    recording,
    paused,
}
class Recorder {
    /** Stores the recorded audio as Blob objects of audio data as the recording continues*/
    audioBlobs: Blob[];
    /** Stores the reference of the MediaRecorder instance that handles the MediaStream when recording starts*/
    mediaRecorder: MediaRecorder | null;
    /** Stores the reference to the stream currently capturing the audio*/
    streamBeingCaptured: MediaStream | null;

    status: StatusRecorder;

    constructor() {
        this.audioBlobs = [];
        this.mediaRecorder = null;
        this.streamBeingCaptured = null;
        this.status = StatusRecorder.idle;
    }

    async start() {
        if (!(navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia)) {
            return Promise.reject(new Error("Методы mediaDevices API или getDisplayMedia не поддерживаются."));
        }
        if (this.status === StatusRecorder.idle) {
            const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const speakerStream = await navigator.mediaDevices.getDisplayMedia({
                audio: true,
                video: true,
            });

            const audioContext = new window.AudioContext();
            const micSource = audioContext.createMediaStreamSource(audioStream);
            const speakerSource = audioContext.createMediaStreamSource(speakerStream);

            const destination = audioContext.createMediaStreamDestination();
            micSource.connect(destination);
            speakerSource.connect(destination);

            const mimeTypes = ["audio/mp4", "audio/webm"].filter((type) => MediaRecorder.isTypeSupported(type));

            if (mimeTypes.length === 0) {
                return Promise.reject(new Error("Не поддерживается браузером."));
            }

            this.streamBeingCaptured = destination.stream;
            this.mediaRecorder = new MediaRecorder(destination.stream, { mimeType: mimeTypes[0] });
            this.audioBlobs = [];

            this.status = StatusRecorder.inited;
        }

        if (this.status === StatusRecorder.paused) {
            this.audioBlobs = [];
        }

        this.mediaRecorder?.start();

        this.status = StatusRecorder.recording;
    }

    pause(): Promise<Blob> {
        return new Promise((resolve) => {
            this.mediaRecorder?.addEventListener("dataavailable", (event: BlobEvent) => {
                // console.log("dataavailable");
                resolve(event.data);
            });

            this.mediaRecorder?.stop();
            this.status = StatusRecorder.paused;
        });
    }

    cancel() {
        this.mediaRecorder?.stop();
        this.stopStream();
        this.resetRecordingProperties();
    }

    stopStream() {
        this.streamBeingCaptured?.getTracks().forEach((track) => track.stop()); // stop each one
    }

    resetRecordingProperties() {
        this.mediaRecorder = null;
        this.streamBeingCaptured = null;
    }
}

export default new Recorder();
